import * as tslib_1 from "tslib";
import { Breakpoints } from '@angular/cdk/layout';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { NavigationStart } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import * as FileSaver from 'file-saver';
import { Observable, Observer, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TreatmentModalComponent } from '../treatment-modal/treatment-modal.component';
import * as moment from 'moment';
import { downloadFile } from '../../../helpers/utils';
export class NewPatientComponent {
    constructor(fb, _convertService, _patientService, _userService, _usersService, _breakpointObserver, _router, _activeRoute, _translocoService, modalServide, snackBar, cd, _contentService, treatmentsService, treatmentAdditionalFunctionalService, chatService, planningService, _messagingService) {
        this.fb = fb;
        this._convertService = _convertService;
        this._patientService = _patientService;
        this._userService = _userService;
        this._usersService = _usersService;
        this._breakpointObserver = _breakpointObserver;
        this._router = _router;
        this._activeRoute = _activeRoute;
        this._translocoService = _translocoService;
        this.modalServide = modalServide;
        this.snackBar = snackBar;
        this.cd = cd;
        this._contentService = _contentService;
        this.treatmentsService = treatmentsService;
        this.treatmentAdditionalFunctionalService = treatmentAdditionalFunctionalService;
        this.chatService = chatService;
        this.planningService = planningService;
        this._messagingService = _messagingService;
        this.gridColumns = [];
        // refreshIncompleteChat: Subject<boolean> = new Subject();
        this.selectedIndex = 0;
        this.selectedIndexOld = 0;
        this.lastIndex = 5;
        this.step1Valid = false;
        this.step2Valid = false;
        this.step3Valid = false;
        this.step4Valid = false;
        this.step5Valid = false;
        this.step6Valid = false;
        this.arcadFlag = false;
        this.arcadDoctorEmail = '';
        this.selectedReturnAddress = '';
        this.availableScheduleTime = [];
        this.patientType = '';
        this.isMobile = false;
        this.allowUseTabs = false;
        this.fileToBeUploaded = [];
        this.fileToBeUploadedMerged = [];
        this.allowDeleteOptionalImages = true;
        this.EmptyFile = null;
        this.isVerifyInProgres = false;
        this.collapsed = false;
        this.originalTreatmentCode = '';
        this.isUploadServiceData = false;
        this.fileScansToBeUploaded = [];
        this.selectedUpperTeeth = [];
        this.selectedLowerTeeth = [];
        this.imagesCollection = [];
        this.storageBaseUrl = environment.storageBaseUrl;
        // doctorAddInfoQuestion2: string;
        // doctorAddInfoQuestion3: string;
        this.doctorAddInfoHowManyPatients = false;
        this.isEditingNotes = false;
        this.waitingForSTL = true;
        this.showImageMandatoryAsterisk = true;
        this.showAllOtherImageMandatoryAsterisk = false;
        this.progressUpload = 0;
        this.progressUploadSaving = false;
        this.singleFileProgressValue = 0;
        this.fileUploadProgressCounter = 0;
        this.currentFileIterator = 0;
        this.totalFileCount = 0;
        this.maxEndResult = 0;
        this.reload = false;
        this.isError = false;
        this.isShowSaveButton = true;
        this.isUploading = false;
        this.speedUpload = 0;
        this.unitUpload = 'Mbps';
        this.SaveAndSend = false;
        this.updateStatusNewValue = '';
        this.isUpdatieStillLoading = false;
        this.webViewerItems = [];
        this.approveOnlyAliases = [];
        this.isMovingEvent = false;
        this.aliasesLoading = false;
        this.isDisableSaveFile = false;
        this.clinicPreferencesUpdade = true;
        this.patientInfoIconColor = 'black';
        this.whiteColor = 'white';
        this.videoLink = '';
        this.treatmentPlanAllMandatoryFieldsCompleted = false;
        this.isDownloadingServiceSheet = false;
        this.SuperAgentName = new FormControl({ value: '', disabled: true });
        this.DistributorName = new FormControl({ value: '', disabled: true });
        this.AgentName = new FormControl({ value: '', disabled: true });
        this.DoctorsCtrl = new FormControl('');
        this._breakpointObserver.observe([
            Breakpoints.Handset
        ]).subscribe(result => {
            this.isMobile = result.matches;
        });
        this._subsctiptions = this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!event.url.startsWith('/treatments/')) {
                    this.treatmentAdditionalFunctionalService.isPanelCollapsed = false;
                }
                if (!this.treatmentId || event.navigationTrigger !== 'popstate') {
                    this.treatmentsService.reset();
                }
            }
        });
    }
    ngOnDestroy() {
        this._subsctiptions.unsubscribe();
        if (this.doctorsSubscriber && !this.doctorsSubscriber.closed) {
            this.doctorsSubscriber.unsubscribe();
        }
        if (this.treatmentsSubscriber && !this.treatmentsSubscriber.closed) {
            this.treatmentsSubscriber.unsubscribe();
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.serviceSheetForm = this.fb.group({
                prescription: [false],
                fingerprint: [''],
                models: [''],
                idTreatment: 0,
                polish: [false]
            });
            this.doctorsSubscriber = this._patientService.doctorsLoaded.subscribe(() => {
                this.setArcad();
            });
            this.treatmentsSubscriber = this._patientService.treatmentsLoaded.subscribe(() => {
                this.setArcad();
            });
            this.treatmentAdditionalFunctionalService.changePanelContentStatus(false);
            this.prepareTeeth();
            this.tabThreeForm = this.fb.group({
                archesType: ['null', [Validators.required, customNullValidator()]],
                patientAgeType: ['null', [Validators.required, customNullValidator()]],
                archesTypeHandle: ['null', [Validators.required, customNullValidator()]],
                patientType: ['null', [Validators.required, customNullValidator()]],
                youngOpSystem: [null, [Validators.required, customNullValidator()]],
                scanLower: [null, Validators.required],
                scanUpper: [null, Validators.required],
                archesSentViaCommunicator: [false],
                clinicProject: [false],
            });
            this._subsctiptions.add(this.tabThreeForm.controls.archesSentViaCommunicator.valueChanges.pipe(debounceTime(25)).subscribe(value => {
                if (value) {
                    this.tabThreeForm.controls.scanLower.clearValidators();
                    this.tabThreeForm.controls.scanLower.setValue(this.tabThreeForm.controls.scanLower.value);
                    this.tabThreeForm.controls.scanUpper.clearValidators();
                    this.tabThreeForm.controls.scanUpper.setValue(this.tabThreeForm.controls.scanUpper.value);
                }
                else {
                    this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
                    this.tabThreeForm.controls.scanLower.setValue(this.tabThreeForm.controls.scanLower.value);
                    this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
                    this.tabThreeForm.controls.scanUpper.setValue(this.tabThreeForm.controls.scanUpper.value);
                }
                this.cd.detectChanges();
            }));
            this.scheduleForm = this.fb.group({
                pickupDate: [null, Validators.required],
                pickupNote: ['']
            });
            this.tabTwoForm = this.fb.group({
                return: ['null', [Validators.required, customNullValidator()]],
                ship: ['null', [Validators.required, customNullValidator()]],
                invoice: ['null', [Validators.required, customNullValidator()]]
            });
            this.tabFourForm = this.fb.group({
                leftSide: [null],
                frontSide: [null],
                rightSide: [null],
                upperArch: [null, Validators.required],
                rsOrthopanoramics: [null],
                telecranio: [null],
                other: [null],
                bottomArch: [null, Validators.required],
                leftOcclusal: [null, Validators.required],
                frontOcclusal: [null, Validators.required],
                rightOcclusal: [null, Validators.required],
                imageType: ['S', Validators.required],
                imageCollage: [null, Validators.required],
            });
            this.tabFourForm.controls.imageType.valueChanges.pipe(debounceTime(10)).subscribe(value => {
                if (this.selectedTreatment && (this.selectedTreatment.phase !== '1' || this.selectedTreatment.phaseType === 'R')
                    && this.selectedTreatment.status === 'INCOMPLETE') {
                    // all is optional
                    this.tabFourForm.controls.upperArch.clearValidators();
                    this.tabFourForm.controls.upperArch.reset();
                    this.tabFourForm.controls.bottomArch.clearValidators();
                    this.tabFourForm.controls.bottomArch.reset();
                    this.tabFourForm.controls.leftOcclusal.clearValidators();
                    this.tabFourForm.controls.leftOcclusal.reset();
                    this.tabFourForm.controls.frontOcclusal.clearValidators();
                    this.tabFourForm.controls.frontOcclusal.reset();
                    this.tabFourForm.controls.rightOcclusal.clearValidators();
                    this.tabFourForm.controls.rightOcclusal.reset();
                    this.tabFourForm.controls.imageCollage.clearValidators();
                    this.tabFourForm.controls.imageCollage.reset();
                }
                else {
                    if (value === 'S') {
                        this.tabFourForm.controls.upperArch.setValidators(Validators.required);
                        this.tabFourForm.controls.upperArch.setValue(this.tabFourForm.controls.upperArch.value);
                        this.tabFourForm.controls.bottomArch.setValidators(Validators.required);
                        this.tabFourForm.controls.bottomArch.setValue(this.tabFourForm.controls.bottomArch.value);
                        this.tabFourForm.controls.leftOcclusal.setValidators(Validators.required);
                        this.tabFourForm.controls.leftOcclusal.setValue(this.tabFourForm.controls.leftOcclusal.value);
                        this.tabFourForm.controls.frontOcclusal.setValidators(Validators.required);
                        this.tabFourForm.controls.frontOcclusal.setValue(this.tabFourForm.controls.frontOcclusal.value);
                        this.tabFourForm.controls.rightOcclusal.setValidators(Validators.required);
                        this.tabFourForm.controls.rightOcclusal.setValue(this.tabFourForm.controls.rightOcclusal.value);
                        this.tabFourForm.controls.imageCollage.clearValidators();
                        this.tabFourForm.controls.imageCollage.reset();
                    }
                    else {
                        this.tabFourForm.controls.upperArch.clearValidators();
                        this.tabFourForm.controls.upperArch.reset();
                        this.tabFourForm.controls.bottomArch.clearValidators();
                        this.tabFourForm.controls.bottomArch.reset();
                        this.tabFourForm.controls.leftOcclusal.clearValidators();
                        this.tabFourForm.controls.leftOcclusal.reset();
                        this.tabFourForm.controls.frontOcclusal.clearValidators();
                        this.tabFourForm.controls.frontOcclusal.reset();
                        this.tabFourForm.controls.rightOcclusal.clearValidators();
                        this.tabFourForm.controls.rightOcclusal.reset();
                        this.tabFourForm.controls.imageCollage.setValidators(Validators.required);
                        this.tabFourForm.controls.imageCollage.setValue(this.tabFourForm.controls.imageCollage.value);
                    }
                }
                this.onTreatmentTypeChange(this.tabThreeForm.controls.patientType.value);
            });
            // this.tabFiveForm = this.fb.group({
            //   noExcludeTeeth: [false, customNullValidator()],
            //   //objectiveRadioButton: [null, Validators.required],
            //   typeOfElastic: [null],
            //   biteRamp: ["null", [Validators.required, customNullValidator()]],
            //   crowdingUpperArch: ["null", [customNullValidator()]],
            //   crowdingLowerArch: ["null", [customNullValidator()]],
            //   // crowdingUpperArchWorkflow: ["null", [Validators.required, customNullValidator()]],
            //   // crowdingLowerArchWorkflow: ["null", [Validators.required, customNullValidator()]],
            //   crowdingUpperArchWorkflow: ["null", [customNullValidator()]],
            //   crowdingLowerArchWorkflow: ["null", [customNullValidator()]],
            //   upperArchDiastema: ["null", [customNullValidator()]],
            //   lowerArchDiastema: ["null", [customNullValidator()]],
            //   changeClass: [null],
            //   //anteriorTreatment: [false, [customFalseValidator()]],
            //   // overJet: [{ value: true, disabled: true }],
            //   // overByte: [{ value: true, disabled: true }],
            //   overJetType: [null, Validators.required],
            //   overByteType: [null, Validators.required],
            //   crossBiteType: [null, Validators.required]
            // });
            this.tabFiveForm = this.fb.group({
                noExcludeTeeth: [false, customNullValidator()],
                youngLimitIncisors: [false, customNullValidator()],
                typeOfElastic: [null],
                biteRamp: ['null'],
                crowdingUpperArch: ['null'],
                crowdingLowerArch: ['null'],
                crowdingUpperArchWorkflow: [[]],
                crowdingLowerArchWorkflow: [[]],
                upperArchDiastema: ['null'],
                lowerArchDiastema: ['null'],
                youngMoveUpperMidline: [false],
                youngMoveLowerMidline: [false],
                youngMoveUpperMidlineSide: [null],
                youngMoveLowerMidlineSide: [null],
                changeClass: [null],
                overJetType: [null],
                youngThroughValue: [null],
                youngCase: [null],
                youngDeepValues: [null],
                youngOpenValues: [null],
                youngMolar: [null],
                youngIPRUpper: [null],
                youngIPRLower: [null],
                youngEndResult: [{ value: 2, disabled: true }, [Validators.max(2), Validators.min(0)]],
                youngElasticMethod: [null],
                overByteType: [null],
                crossBiteType: [null]
            });
            this.tabFiveForm.controls.changeClass.valueChanges.subscribe(resp => {
                if (this.tabFiveForm.controls.changeClass.value) {
                    // this.tabFiveForm.controls.anteriorTreatment.clearValidators();
                    this.selectedTreatment.elasticType === 'YS' ?
                        this.tabFiveForm.controls.typeOfElastic.setValue('YS') :
                        this.selectedTreatment.elasticType === 'NO' ?
                            this.tabFiveForm.controls.typeOfElastic.setValue('NO') :
                            this.tabFiveForm.controls.typeOfElastic.setValue(null);
                }
                else {
                    this.tabFiveForm.controls.typeOfElastic.setValue('N');
                }
            });
            this.tabFiveForm.controls.noExcludeTeeth.valueChanges.subscribe(resp => {
                if (!this.tabFiveForm.controls.noExcludeTeeth.value && (this.selectedTreatment.teethToExclude.length === 0)) {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'required': true });
                }
                else {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors(null);
                }
            });
            this.tabSixForm = this.fb.group({
                noSpecialInstruction: [false],
                specialInstruction: [null, [Validators.required]],
                videoRequested: [false],
            });
            this.currentUser = this._userService.readUserFromLocalStorage();
            if (this.currentUser.role === 'DO') {
                this.tabOneForm = this.fb.group({
                    patientType: ['gdpr', Validators.required],
                    patientFirstName: [null],
                    patientLastName: [null],
                    // tslint:disable-next-line:max-line-length
                    registrationDate: [{ value: new Date(this._convertService.dateConvert(new Date(Date.now()).toUTCString(), 'YYYY-MM-DD')), disabled: true }, Validators.required],
                    patientGDPR: [''],
                    changeCheckBox: [false],
                    plannedShippingDate: null,
                    idDoctor: [{ value: this.currentUser.idAdmin, disabled: true }, [Validators.required, customNullValidator()]],
                });
                this.doctors = [this.currentUser];
            }
            else {
                this.tabOneForm = this.fb.group({
                    patientType: ['gdpr', Validators.required],
                    patientFirstName: [''],
                    patientLastName: [''],
                    // tslint:disable-next-line:max-line-length
                    registrationDate: [{ value: new Date(this._convertService.dateConvert(new Date(Date.now()).toUTCString(), 'YYYY-MM-DD')), disabled: true }, Validators.required],
                    patientGDPR: [''],
                    changeCheckBox: [false],
                    plannedShippingDate: null,
                    idDoctor: ['null', [Validators.required, customNullValidator()]],
                });
            }
            this.isLoading = true;
            this._patientService.getDoctorsLite().subscribe(resp => {
                this.doctors = resp;
                this.isLoading = false;
                this._patientService.doctorsIsLoaded();
                this.cd.detectChanges();
            });
            this._activeRoute.params.subscribe(param => {
                this.treatmentId = param['id'];
                if (this.treatmentId) {
                    this.prepareTreatmentCall();
                }
            });
            this._subsctiptions.add(this.tabThreeForm.controls.archesTypeHandle.valueChanges.pipe(debounceTime(25)).subscribe(value => {
                // console.log('archesTypeHandle', value);
                // tslint:disable-next-line:triple-equals
                if (this.tabThreeForm.controls.archesType.value === 'T' || this.tabThreeForm.controls.archesType.value == 'null') {
                    // return;
                }
                switch (value) {
                    case 'U':
                        // this.tabThreeForm.controls.scanLower.clearValidators();
                        // this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
                        // this.tabThreeForm.controls.scanLower.reset();
                        // this.selectedTreatment.scanLower = null;
                        this.tabFiveForm.controls.crowdingLowerArch.setValue(false);
                        this.tabFiveForm.controls.crowdingLowerArch.setErrors(null);
                        this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([]);
                        this.tabFiveForm.controls.crowdingLowerArchWorkflow.clearValidators();
                        this.tabFiveForm.controls.crowdingLowerArchWorkflow.updateValueAndValidity();
                        // tslint:disable-next-line:max-line-length
                        // if ((this.tabFiveForm.controls.upperArchDiastema.value == "N") || (this.tabFiveForm.controls.upperArchDiastema.value == null)) {
                        //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
                        // }
                        this.tabFiveForm.controls.lowerArchDiastema.setErrors(null);
                        this.tabFiveForm.controls.lowerArchDiastema.setValue('N');
                        this.tabFiveForm.controls.lowerArchDiastema.updateValueAndValidity();
                        break;
                    case 'L':
                        // this.tabThreeForm.controls.scanUpper.clearValidators();
                        // this.tabThreeForm.controls.scanUpper.reset();
                        // this.selectedTreatment.scanUpper = null;
                        this.tabFiveForm.controls.crowdingUpperArch.setValue(false);
                        this.tabFiveForm.controls.crowdingUpperArch.setErrors(null);
                        this.tabFiveForm.controls.crowdingUpperArchWorkflow.setErrors(null);
                        this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
                        this.tabFiveForm.controls.crowdingUpperArchWorkflow.clearValidators();
                        this.tabFiveForm.controls.crowdingUpperArchWorkflow.updateValueAndValidity();
                        this.tabFiveForm.controls.upperArchDiastema.setValue('N');
                        this.tabFiveForm.controls.upperArchDiastema.clearValidators();
                        this.tabFiveForm.controls.upperArchDiastema.updateValueAndValidity();
                        // if (this.tabFiveForm.controls.lowerArchDiastema.value == "N" || (this.tabFiveForm.controls.lowerArchDiastema.value == null)) {
                        //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
                        // }
                        break;
                    case 'B':
                    case 'null':
                        if (this.selectedTreatment.crowdingLowerArchWorkflow === ([])) {
                            this.tabFiveForm.controls.crowdingLowerArch.setValue(false);
                            this.tabFiveForm.controls.crowdingLowerArch.setErrors(null);
                            this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([]);
                        }
                        if (this.selectedTreatment.crowdingUpperArchWorkflow === ([])) {
                            this.tabFiveForm.controls.crowdingUpperArch.setValue(false);
                            this.tabFiveForm.controls.crowdingUpperArch.setErrors(null);
                            this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
                        }
                        this.tabFiveForm.controls.lowerArchDiastema.updateValueAndValidity();
                        this.tabFiveForm.controls.upperArchDiastema.updateValueAndValidity();
                        this.tabFiveForm.controls.noExcludeTeeth.updateValueAndValidity();
                        // let lowerArchDiastema = this.tabFiveForm.controls.lowerArchDiastema.value;
                        // let upperArchDiastema = this.tabFiveForm.controls.upperArchDiastema.value;
                        // if (((lowerArchDiastema != "N") && (upperArchDiastema == "N")) || ((lowerArchDiastema == "N") && (upperArchDiastema != "N"))) {
                        //   this.tabFiveForm.controls.lowerArchDiastema.setValue(null);
                        //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
                        //   this.tabFiveForm.controls.upperArchDiastema.setValue(null);
                        //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
                        // }
                        // tslint:disable-next-line:max-line-length
                        // if ((this.tabFiveForm.controls.upperArchDiastema.value == "N") || (this.tabFiveForm.controls.upperArchDiastema.value == null)) {
                        //   this.tabFiveForm.controls.upperArchDiastema.setErrors({ 'required': true });
                        // }
                        // if (this.tabFiveForm.controls.lowerArchDiastema.value == "N" || (this.tabFiveForm.controls.lowerArchDiastema.value == null)) {
                        //   this.tabFiveForm.controls.lowerArchDiastema.setErrors({ 'required': true });
                        // }
                        break;
                    default:
                        // this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
                        // this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
                        const upperValue = this.tabThreeForm.controls.scanUpper.value;
                        const lowerValue = this.tabThreeForm.controls.scanLower.value;
                        this.tabThreeForm.controls.scanUpper.reset();
                        this.tabThreeForm.controls.scanLower.reset();
                        this.tabThreeForm.controls.scanUpper.setValue(upperValue);
                        this.tabThreeForm.controls.scanLower.setValue(lowerValue);
                        break;
                }
                this.cd.detectChanges();
            }));
            this._activeRoute.queryParams.subscribe(param => {
                const step = param['step'];
                if (step && (+step > 0 && +step < 6)) {
                    this.selectedIndex = (+step) - 1;
                }
            });
            this.collapsed = this.treatmentAdditionalFunctionalService.isPanelCollapsed;
            this.cd.detectChanges();
        });
    }
    isTreatmentTypeYoung() {
        return this.tabThreeForm.controls.patientType.value === 'YO' || this.tabThreeForm.controls.patientType.value === 'YP';
    }
    checkEndResultValue(event) {
        const value = event.target.value * 1;
        if (value > 2) {
            event.target.value = 2;
            return false;
        }
        if (value < 0) {
            event.target.value = 0;
            return false;
        }
    }
    setArcad() {
        if (this.currentUser.role === 'US' && this.selectedTreatment && this.doctors && this.treatmentId) {
            const currentDoctor = this.doctors.find((doc) => doc.idAdmin === this.selectedTreatment.idDoctor);
            if (currentDoctor) {
                this.arcadFlag = currentDoctor.arcadFlag;
                this.arcadDoctorEmail = currentDoctor.email;
            }
            console.log('isArcad Doctor', this.arcadFlag);
        }
    }
    prepareTeeth() {
        this.upperTeeth = [];
        this.lowerTeeth = [];
        this.upperTeeth.push({ id: '1,8', src: '../../../../assets/img/teeth/1,8.png', style: '1%', isSelected: false }, { id: '1,7', src: '../../../../assets/img/teeth/1,7.png', style: '1%', isSelected: false }, { id: '1,6', src: '../../../../assets/img/teeth/1,6.png', style: '1%', isSelected: false }, { id: '1,5', src: '../../../../assets/img/teeth/1,5.png', style: '0.8%', isSelected: false }, { id: '1,4', src: '../../../../assets/img/teeth/1,4.png', style: '0.8%', isSelected: false }, { id: '1,3', src: '../../../../assets/img/teeth/1,3.png', style: '0.5%', isSelected: false }, { id: '1,2', src: '../../../../assets/img/teeth/1,2.png', style: '0.4%', isSelected: false }, { id: '1,1', src: '../../../../assets/img/teeth/1,1.png', style: '0.6%', isSelected: false }, { id: '2,1', src: '../../../../assets/img/teeth/2,1.png', style: '0.7%', isSelected: false }, { id: '2,2', src: '../../../../assets/img/teeth/2,2.png', style: '0.4%', isSelected: false }, { id: '2,3', src: '../../../../assets/img/teeth/2,3.png', style: '0.5%', isSelected: false }, { id: '2,4', src: '../../../../assets/img/teeth/2,4.png', style: '0.7%', isSelected: false }, { id: '2,5', src: '../../../../assets/img/teeth/2,5.png', style: '0.8%', isSelected: false }, { id: '2,6', src: '../../../../assets/img/teeth/2,6.png', style: '1%', isSelected: false }, { id: '2,7', src: '../../../../assets/img/teeth/2,7.png', style: '1%', isSelected: false }, { id: '2,8', src: '../../../../assets/img/teeth/2,8.png', style: '1%', isSelected: false });
        this.lowerTeeth.push({ id: '4,8', src: '../../../../assets/img/teeth/4,8.png', style: '1.2%', isSelected: false }, { id: '4,7', src: '../../../../assets/img/teeth/4,7.png', style: '1.3%', isSelected: false }, { id: '4,6', src: '../../../../assets/img/teeth/4,6.png', style: '1.2%', isSelected: false }, { id: '4,5', src: '../../../../assets/img/teeth/4,5.png', style: '0.7%', isSelected: false }, { id: '4,4', src: '../../../../assets/img/teeth/4,4.png', style: '0.7%', isSelected: false }, { id: '4,3', src: '../../../../assets/img/teeth/4,3.png', style: '0.5%', isSelected: false }, { id: '4,2', src: '../../../../assets/img/teeth/4,2.png', style: '0.15%', isSelected: false }, { id: '4,1', src: '../../../../assets/img/teeth/4,1.png', style: '0.3%', isSelected: false }, { id: '3,1', src: '../../../../assets/img/teeth/3,1.png', style: '0.2%', isSelected: false }, { id: '3,2', src: '../../../../assets/img/teeth/3,2.png', style: '0.2%', isSelected: false }, { id: '3,3', src: '../../../../assets/img/teeth/3,3.png', style: '0.5%', isSelected: false }, { id: '3,4', src: '../../../../assets/img/teeth/3,4.png', style: '0.6%', isSelected: false }, { id: '3,5', src: '../../../../assets/img/teeth/3,5.png', style: '0.7%', isSelected: false }, { id: '3,6', src: '../../../../assets/img/teeth/3,6.png', style: '1.2%', isSelected: false }, { id: '3,7', src: '../../../../assets/img/teeth/3,7.png', style: '1.2%', isSelected: false }, { id: '3,8', src: '../../../../assets/img/teeth/3,8.png', style: '1.2%', isSelected: false });
    }
    selectUpperTooth(upperTeeth) {
        // console.log('selectUpperTooth');
        if (this.disabled) {
            return;
        }
        this.selectedUpperTeeth = [];
        this.upperTeeth.forEach(x => {
            if (upperTeeth.id === x.id) {
                x.isSelected = !x.isSelected;
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedUpperTeeth.push(...this.upperTeeth.filter(x => x.isSelected).map(x => x.id));
                if (x.isSelected) {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors(null);
                }
                else if (!x.isSelected && (this.selectedUpperTeeth.length === 0) && (this.selectedLowerTeeth.length === 0)) {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'invalid': true });
                }
            }
        });
    }
    selectLowerTooth(lowerTeeth) {
        if (this.disabled) {
            return;
        }
        this.selectedLowerTeeth = [];
        this.lowerTeeth.forEach(x => {
            if (lowerTeeth.id === x.id) {
                x.isSelected = !x.isSelected;
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedLowerTeeth.push(...this.lowerTeeth.filter(x => x.isSelected).map(x => x.id));
                if (x.isSelected) {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors(null);
                }
                else if (!x.isSelected && (this.selectedUpperTeeth.length === 0) && (this.selectedLowerTeeth.length === 0)) {
                    this.tabFiveForm.controls.noExcludeTeeth.setErrors({ 'invalid': true });
                }
            }
        });
    }
    // updateTreatmentMandatoryFieldsCall(event){
    //   console.log('updateTreatmentMandatoryFieldsCall', event.value);
    //   this.treatmentPlanAllMandatoryFieldsCompleted = event.value;
    // }
    prepareTreatmentCall() {
        console.log('prepareTreatmentCall');
        this._patientService.getTreatment(this.treatmentId).subscribe((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedTreatment = resp;
            this._patientService.getServiceSheet(this.selectedTreatment.idTreatment).subscribe(res => {
                this.serviceSheetForm.setValue(res);
            });
            this._patientService.treatmentIsLoaded();
            // if it's not phase 1
            this.originalTreatmentCode = this.selectedTreatment.treatmentCode;
            // tslint:disable-next-line:triple-equals
            if (this.selectedTreatment.phaseType === 'P' && this.selectedTreatment.phase == '1') {
                // first phase
                console.log('Phase 1');
            }
            else {
                console.log('Next Phase: ');
                if (this.selectedTreatment.archesType === 'D') {
                    // if (this.selectedTreatment.archesSentViaCommunicator) {
                    //   if (this.selectedTreatment.scheduleDate2 == null)
                    //     this.selectedTreatment.treatmentCode = '----------';
                    // } else {
                    // tslint:disable-next-line:max-line-length
                    //   if (this.selectedTreatment.scheduleDate2 != null && (this.selectedTreatment.scanLower != '' || this.selectedTreatment.scanUpper != '')){
                    //   }else{
                    //     this.selectedTreatment.treatmentCode = '----------';
                    //   }
                    // }
                    if (this.selectedTreatment.waitingForSTL === false && this.selectedTreatment.scheduleDate2 == null) {
                        this.selectedTreatment.treatmentCode = '----------';
                    }
                }
                else {
                    if (this.selectedTreatment.scheduleDate2 == null) {
                        this.selectedTreatment.treatmentCode = '----------';
                    }
                }
                // if (this.selectedTreatment.scheduleDate2 == null && this.selectedTreatment.archesSentViaCommunicator) {
                //   this.selectedTreatment.treatmentCode = '----------';
                // } else if (this.selectedTreatment.scheduleDate2 != null && this.selectedTreatment.archesSentViaCommunicator == false ) {
                // }
            }
            if (this.currentUser.userGroup === 'PJ') {
                this.getClinicPreferencesFlag();
            }
            this.updateStatusNewValue = this.selectedTreatment.status;
            if (this.selectedTreatment.reasonIncompleteOptions.length > 0) {
                try {
                    const reasons = yield this._usersService.getOptions('IR').toPromise();
                    // tslint:disable-next-line:max-line-length
                    this.selectedTreatment.reasonOption = reasons.filter(x => this.selectedTreatment.reasonIncompleteOptions.includes(x.optionCode)).map(x => this.getCurrentOptTittle(x)).join(', ');
                }
                catch (_a) {
                }
            }
            this.prepareTabOne();
            this.prepareTabTwo();
            this.prepareTabThree();
            this.prepareTabFour();
            this.prepareTabFive();
            this.prepareTabSix();
            // this.setStepsValidationFlags();
            // tslint:disable-next-line:max-line-length
            // if ((this.currentUser.role == "DO" || this.currentUser.role == "SA" || this.currentUser.role == "DI" || this.currentUser.role == "AG")
            if (this.currentUser.role === 'DO' && (this.selectedTreatment.status === 'DRAFT' || this.selectedTreatment.status === 'INCOMPLETE')) {
            }
            else if (this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST' && this.selectedTreatment.status === 'DRAFT') {
                // START USER (DRAFT) => enable only step3
                this.tabOneForm.disable();
                this.tabTwoForm.disable();
                this.tabFourForm.disable();
                this.tabFiveForm.disable();
                this.tabSixForm.disable();
                this.disabled = true;
                this.isShowSaveButton = false;
            }
            else if (this.currentUser.role === 'US' &&
                // tslint:disable-next-line:max-line-length
                this.currentUser.userGroup === 'ED' && this.selectedTreatment.status !== 'PRODUCTION' && this.selectedTreatment.status !== 'SHIPPED') {
                // EDITOR USER => enable steps 2,3,4,5,6
                this.tabOneForm.disable();
            }
            else if (this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST' &&
                this.selectedTreatment.status === 'MIGRATED') {
                // EDITOR USER => enable steps 2,3,4,5,6
                this.tabOneForm.disable();
            }
            else {
                this.tabOneForm.disable();
                this.tabTwoForm.disable();
                this.tabThreeForm.disable();
                this.tabFourForm.disable();
                this.tabFiveForm.disable();
                this.tabSixForm.disable();
                this.disabled = true;
                this.isShowSaveButton = false;
            }
            if (this.currentUser.executiveArea === 'P' || this.currentUser.userGroup === 'ST') {
                this.tabSixForm.controls.videoRequested.enable();
                this.isShowSaveButton = true;
            }
            this.disabledOptionalImages = this.disabled;
            // tslint:disable-next-line:max-line-length
            if (this.currentUser.role === 'DO' && (this.selectedTreatment.status === 'INPROJECT' || this.selectedTreatment.status === 'INREVIEW')) {
                // DOCTOR CAN EDIT SHIPPING AND INVOICE ADDRESSES
                this.tabTwoForm.controls.ship.enable();
                this.tabTwoForm.controls.invoice.enable();
                // DOCTOT CAN UPLOAD OPTIONAL IMAGES ON STEP 4
                // this.tabFourForm.controls.leftSide.enable();
                this.disabledOptionalImages = false;
                this.allowDeleteOptionalImages = false;
            }
            // force change patient data checkbox
            if (this.currentUser && this.currentUser.role === 'EX' && this.currentUser.executiveArea === 'A') {
                this.tabOneForm.controls.changeCheckBox.enable();
            }
            // force planned shipping date for START for 2nd Phases/Revisions
            if (this.selectedTreatment && this.currentUser && this.currentUser.role === 'US' &&
                (this.selectedTreatment.phase !== '1' || this.selectedTreatment.phaseType === 'R')) {
                if (this.currentUser.userGroup === 'ST') {
                    this.tabOneForm.controls.plannedShippingDate.enable();
                }
            }
            // highlight mandatory fields
            if (this.currentUser.role === 'DO' && (this.selectedTreatment.status === 'DRAFT' || this.selectedTreatment.status === 'INCOMPLETE')) {
                this.tabTwoForm.markAllAsTouched();
                this.tabThreeForm.markAllAsTouched();
                this.tabFiveForm.markAllAsTouched();
                this.tabSixForm.markAllAsTouched();
            }
            // tslint:disable-next-line:no-shadowed-variable
            this._usersService.getOptions('ET').subscribe((resp) => {
                this.typesOfElastic = resp.sort(x => x.optionTitle_EN && x.optionTitle_IT);
            });
            // patient info color
            if (this.selectedTreatment.patientAdditionalNotes != null && this.selectedTreatment.patientAdditionalNotes !== '') {
                this.patientInfoIconColor = 'red';
            }
            // disable fields for Phase 2 or Revisions
            if (this.selectedTreatment && (this.selectedTreatment.phase !== '1' || this.selectedTreatment.phaseType === 'R')
                && this.selectedTreatment.status === 'INCOMPLETE') {
                console.log('FASE 2 O REVISIONE - NON CONFORME!!!');
                this.showImageMandatoryAsterisk = false;
                this.tabThreeForm.controls['patientAgeType'].disable();
                this.tabThreeForm.controls['archesTypeHandle'].disable();
                this.tabThreeForm.controls['patientType'].disable();
                this.tabThreeForm.controls['clinicProject'].disable();
                setTimeout(() => {
                    this.tabFourForm.controls.upperArch.clearValidators();
                    this.tabFourForm.controls.upperArch.reset();
                    this.tabFourForm.controls.bottomArch.clearValidators();
                    this.tabFourForm.controls.bottomArch.reset();
                    this.tabFourForm.controls.leftOcclusal.clearValidators();
                    this.tabFourForm.controls.leftOcclusal.reset();
                    this.tabFourForm.controls.frontOcclusal.clearValidators();
                    this.tabFourForm.controls.frontOcclusal.reset();
                    this.tabFourForm.controls.rightOcclusal.clearValidators();
                    this.tabFourForm.controls.rightOcclusal.reset();
                    this.tabFourForm.controls.imageCollage.clearValidators();
                    this.tabFourForm.controls.imageCollage.reset();
                    this.cd.detectChanges();
                }, 500);
                this.tabFiveForm.controls['noExcludeTeeth'].disable();
                this.tabFiveForm.controls['changeClass'].disable();
                this.tabFiveForm.controls['overByteType'].disable();
                this.tabFiveForm.controls['overJetType'].disable();
                this.tabFiveForm.controls['crossBiteType'].disable();
                this.tabFiveForm.controls['crowdingUpperArch'].disable();
                this.tabFiveForm.controls['crowdingUpperArchWorkflow'].disable();
                this.tabFiveForm.controls['crowdingLowerArch'].disable();
                this.tabFiveForm.controls['crowdingLowerArchWorkflow'].disable();
                this.tabFiveForm.controls['upperArchDiastema'].disable();
                this.tabFiveForm.controls['lowerArchDiastema'].disable();
                this.tabFiveForm.updateValueAndValidity();
            }
            this.cd.detectChanges();
        }), error => {
            this.snackBar.open(this._translocoService.translate('patient.patientNotFoundException'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this._router.navigate(['/dashboard'], { replaceUrl: true });
        });
        this.cd.detectChanges();
    }
    removePlannedShippingDate() {
        this.tabOneForm.controls.plannedShippingDate.setValue(null);
        this.savePlannedShippingDate(true);
        this.closeRemoveConfirmModal();
    }
    openConfirmRemoveModal(modal) {
        this.confirmDeleteModal = this.modalServide.open(modal, { closeOnNavigation: true });
    }
    closeRemoveConfirmModal() {
        this.confirmDeleteModal.close();
    }
    showEvaluationModal(ref) {
        if (this.selectedTreatment.status === 'EVALUATION' && this.selectedTreatment.treatmentCode === '----------') {
            // block evaluation if treatment code is not generated yet
            this.modalMessage = 'patient.GENERAL_INFO.generateTreatmentCodePreventMessage';
            this.infoModal = this.modalServide.open(ref, { disableClose: true });
        }
        else {
            this.additionalModalText = this._translocoService.translate('patient.evaluateModalText');
            this.additionalModal = this.modalServide.open(ref, { disableClose: true });
        }
    }
    closeConfirmEvaluationModal() {
        this.confirmEvaluationModal.close();
    }
    confirmEvaluation() {
        this.closeConfirmEvaluationModal();
        this.showEvaluationModal(this.confirmEvaluationModalRef);
    }
    savePlannedShippingDate(isRemove) {
        this.isLoading = true;
        let momentVariable;
        let stringvalue;
        if (!isRemove) {
            momentVariable = moment(this.tabOneForm.controls.plannedShippingDate.value, 'DD/MM/YYYY');
            stringvalue = momentVariable.format('YYYY-MM-DD');
        }
        const request = {
            idTreatment: +this.selectedTreatment.idTreatment
        };
        if (isRemove) {
            request['shippingDate'] = null;
        }
        else {
            request['shippingDate'] = stringvalue;
        }
        const method = isRemove ? 'removePlannedShippingDate' : 'updatePlannedShippingDate';
        this.treatmentsService[method](request).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.UpdatePlannedShippingDateMessage'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.cd.detectChanges();
        }, error => {
            // error while saving new planning
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.cd.detectChanges();
        });
    }
    setStepsValidationFlags() {
        // debugger;
        // set flags for 6 Steps (enabled/disabled)
        // if (this.selectedTreatment.status == "DRAFT" || this.selectedTreatment.status == "INCOMPLETE") {
        //   this.step1Valid = this.tabOneForm.valid;
        //   this.step2Valid = this.tabTwoForm.valid;
        //   this.step3Valid = this.tabThreeForm.valid;
        //   this.step4Valid = this.tabFourForm.valid;
        //   this.step5Valid = this.tabFiveForm.valid;
        //   this.step6Valid = this.tabSixForm.valid;
        // }
        // else {
        //   //if status is ahead of DRAFT or INCOMPLETE => all 6 steps are VALID
        //   this.step1Valid = true;
        //   this.step2Valid = true;
        //   this.step3Valid = true;
        //   this.step4Valid = true;
        //   this.step5Valid = true;
        //   this.step6Valid = true;
        // }
        // console.log('selectedTreatment', this.selectedTreatment);
        // console.log('step2Valid: ' , this.step2Valid);
        // console.log('step4Valid: ' , this.step4Valid);
        // console.log('step5Valid: ' , this.step5Valid);
        // console.log('step6Valid: ' , this.step6Valid);
        this.tabFiveForm.updateValueAndValidity();
        this.tabFiveForm.valueChanges.subscribe(x => {
            console.log('form value changed', x);
        });
    }
    increase() {
        const currentValue = this.tabFiveForm.controls.youngEndResult.value;
        this.tabFiveForm.controls.youngEndResult.setValue(currentValue + 0.5);
    }
    decrease() {
        const currentValue = this.tabFiveForm.controls.youngEndResult.value;
        this.tabFiveForm.controls.youngEndResult.setValue(currentValue - 0.5);
    }
    prepareTabSix() {
        setTimeout(() => {
            this.tabSixForm.controls.noSpecialInstruction.setValue(this.selectedTreatment.noSpecialInstruction);
            this.tabSixForm.controls.specialInstruction.setValue(this.selectedTreatment.specialInstruction);
            this.tabSixForm.controls.videoRequested.setValue(this.selectedTreatment.videoRequested);
        }, 0);
        this.onSpecialInstructioCheckBox(this.selectedTreatment.noSpecialInstruction);
    }
    prepareTabFive() {
        this.tabFiveForm.controls.noExcludeTeeth.setValue(this.selectedTreatment.noTeethToExclude);
        // this.tabFiveForm.controls.objectiveRadioButton.setValue(this.selectedTreatment.treatmentAction);
        this.tabFiveForm.controls.typeOfElastic.setValue(this.selectedTreatment.elasticType === '' ? null : this.selectedTreatment.elasticType);
        this.tabFiveForm.controls.biteRamp.setValue(this.selectedTreatment.byteRamp);
        this.tabFiveForm.controls.crowdingUpperArch.setValue(this.selectedTreatment.crowdingUpperArch);
        this.tabFiveForm.controls.crowdingLowerArch.setValue(this.selectedTreatment.crowdingLowerArch);
        // tslint:disable-next-line:max-line-length
        // this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue(this.selectedTreatment.crowdingUpperArchType == "N" ? 'null' : this.selectedTreatment.crowdingUpperArchType);
        // tslint:disable-next-line:max-line-length
        // this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue(this.selectedTreatment.crowdingLowerArchType == "N" ? 'null' : this.selectedTreatment.crowdingLowerArchType);
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue(this.selectedTreatment.crowdingUpperArchType.length === 0 ? null : this.selectedTreatment.crowdingUpperArchType);
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue(this.selectedTreatment.crowdingLowerArchType.length === 0 ? null : this.selectedTreatment.crowdingLowerArchType);
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.upperArchDiastema.setValue(this.selectedTreatment.upperArchDiastema === 'N' ? null : this.selectedTreatment.upperArchDiastema);
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.lowerArchDiastema.setValue(this.selectedTreatment.lowerArchDiastema === 'N' ? null : this.selectedTreatment.lowerArchDiastema);
        this.tabFiveForm.controls.changeClass.setValue(this.selectedTreatment.changeClass);
        this.tabFiveForm.controls.youngMoveUpperMidline.setValue(this.selectedTreatment.youngMoveUpperMidline);
        this.tabFiveForm.controls.youngMoveLowerMidline.setValue(this.selectedTreatment.youngMoveLowerMidline);
        this.tabFiveForm.controls.youngMoveUpperMidlineSide.setValue(this.selectedTreatment.youngMoveUpperMidlineSide);
        this.tabFiveForm.controls.youngMoveLowerMidlineSide.setValue(this.selectedTreatment.youngMoveLowerMidlineSide);
        this.tabFiveForm.controls.youngThroughValue.setValue(this.selectedTreatment.youngThroughValue);
        this.tabFiveForm.controls.youngCase.setValue(this.selectedTreatment.youngCase);
        this.tabFiveForm.controls.youngDeepValues.setValue(this.selectedTreatment.youngDeepValues);
        this.tabFiveForm.controls.youngOpenValues.setValue(this.selectedTreatment.youngOpenValues);
        this.tabFiveForm.controls.youngMolar.setValue(this.selectedTreatment.youngMolar);
        this.tabFiveForm.controls.youngIPRUpper.setValue(this.selectedTreatment.youngIPRUpper);
        this.tabFiveForm.controls.youngIPRLower.setValue(this.selectedTreatment.youngIPRLower);
        this.tabFiveForm.controls.youngEndResult.setValue(this.selectedTreatment.youngEndResult);
        this.tabFiveForm.controls.youngElasticMethod.setValue(this.selectedTreatment.youngElasticMethod);
        this.tabFiveForm.controls.youngLimitIncisors.setValue(this.selectedTreatment.youngLimitIncisors);
        // this.tabFiveForm.controls.anteriorTreatment.setValue(this.selectedTreatment.anteriorTreatment);
        this.tabFiveForm.controls.overJetType.setValue(this.selectedTreatment.overJetType === 'N' ? null : this.selectedTreatment.overJetType);
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.overByteType.setValue(this.selectedTreatment.overByteType === 'N' ? null : this.selectedTreatment.overByteType);
        // tslint:disable-next-line:max-line-length
        this.tabFiveForm.controls.crossBiteType.setValue(this.selectedTreatment.crossBiteType === 'N' ? null : this.selectedTreatment.crossBiteType);
        this.lowerTeeth.forEach(x => {
            if (this.selectedTreatment.teethToExclude.includes(x.id)) {
                x.isSelected = true;
            }
        });
        this.upperTeeth.forEach(x => {
            if (this.selectedTreatment.teethToExclude.includes(x.id)) {
                x.isSelected = true;
            }
        });
        this.onCrowdingLowerSelect(this.selectedTreatment.crowdingLowerArch);
        this.onCrowdingUpperSelect(this.selectedTreatment.crowdingUpperArch);
    }
    prepareTabFour() {
        this.tabFourForm.controls.leftSide.setValue(this.selectedTreatment.imageLS);
        this.tabFourForm.controls.frontSide.setValue(this.selectedTreatment.imageFS);
        this.tabFourForm.controls.rightSide.setValue(this.selectedTreatment.imageRS);
        this.tabFourForm.controls.upperArch.setValue(this.selectedTreatment.imageUA);
        this.tabFourForm.controls.rsOrthopanoramics.setValue(this.selectedTreatment.imageOrtho);
        this.tabFourForm.controls.telecranio.setValue(this.selectedTreatment.imageTelecranio);
        this.tabFourForm.controls.other.setValue(this.selectedTreatment.imageOther);
        this.tabFourForm.controls.bottomArch.setValue(this.selectedTreatment.imageBA);
        this.tabFourForm.controls.leftOcclusal.setValue(this.selectedTreatment.imageLO);
        this.tabFourForm.controls.frontOcclusal.setValue(this.selectedTreatment.imageFO);
        this.tabFourForm.controls.rightOcclusal.setValue(this.selectedTreatment.imageRO);
        this.tabFourForm.controls.imageType.setValue(this.selectedTreatment.imageType);
        this.tabFourForm.controls.imageCollage.setValue(this.selectedTreatment.imageCollage);
    }
    prepareTabThree() {
        this.waitingForSTL = this.selectedTreatment.waitingForSTL;
        if (this.selectedTreatment.archesType === 'D' || this.isAllowToViewScanDnD()) {
            this.tabThreeForm.controls.scanLower.setValue(this.selectedTreatment.scanLower ? this.selectedTreatment.scanLower : null);
            this.tabThreeForm.controls.scanUpper.setValue(this.selectedTreatment.scanUpper ? this.selectedTreatment.scanUpper : null);
            this.tabThreeForm.controls.archesSentViaCommunicator.setValue(this.selectedTreatment.archesSentViaCommunicator);
        }
        else {
            this.tabThreeForm.controls.scanLower.clearValidators();
            this.tabThreeForm.controls.scanUpper.clearValidators();
            this.tabThreeForm.controls.scanUpper.reset();
            this.tabThreeForm.controls.scanLower.reset();
        }
        if (this.selectedTreatment.archesType === 'N') {
            this.tabThreeForm.controls.archesType.setValue(null);
        }
        else {
            this.tabThreeForm.controls.archesType.setValue(this.selectedTreatment.archesType);
        }
        // tslint:disable-next-line:max-line-length
        this.tabThreeForm.controls.patientAgeType.setValue(this.selectedTreatment.patientType === 'N' ? 'null' : this.selectedTreatment.patientType);
        // tslint:disable-next-line:max-line-length
        this.tabThreeForm.controls.archesTypeHandle.setValue(this.selectedTreatment.archesHandled === 'N' ? 'null' : this.selectedTreatment.archesHandled);
        // tslint:disable-next-line:max-line-length
        this.tabThreeForm.controls.patientType.setValue(this.selectedTreatment.treatmentType === 'NO' ? 'null' : this.selectedTreatment.treatmentType);
        this.tabThreeForm.controls.clinicProject.setValue(this.selectedTreatment.clinicProject);
        this.tabThreeForm.controls.youngOpSystem.setValue(this.selectedTreatment.youngOpSystem);
    }
    prepareTabTwo() {
        if (this.selectedTreatment.idAddressReturn === -1) {
            // tslint:disable-next-line:max-line-length
            this.tabTwoForm.controls.return.setValue(this.selectedTreatment.idAddressReturn === 0 ? 'null' : this.selectedTreatment.idAddressReturn.toString());
        }
        else {
            // tslint:disable-next-line:max-line-length
            this.tabTwoForm.controls.return.setValue(this.selectedTreatment.idAddressReturn === 0 ? 'null' : this.selectedTreatment.idAddressReturn);
        }
        // tslint:disable-next-line:max-line-length
        this.tabTwoForm.controls.ship.setValue(this.selectedTreatment.idAddressShipping === 0 ? 'null' : this.selectedTreatment.idAddressShipping);
        // tslint:disable-next-line:max-line-length
        this.tabTwoForm.controls.invoice.setValue(this.selectedTreatment.idAddressInvoice === 0 ? 'null' : this.selectedTreatment.idAddressInvoice);
    }
    prepareTabOne() {
        this.tabOneForm.controls.patientFirstName.setValue(this.selectedTreatment.patientFirstName);
        this.tabOneForm.controls.patientLastName.setValue(this.selectedTreatment.patientLastName);
        this.tabOneForm.controls.registrationDate.setValue(this.selectedTreatment.registrationDate);
        this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
        this.tabOneForm.controls.idDoctor.setValue(this.selectedTreatment.idDoctor);
        this.tabOneForm.controls.plannedShippingDate.setValue(this.selectedTreatment.plannedShippingDate);
        this.onRadioButtonChange(this.tabOneForm.controls.patientGDPR.value
            ? 'gdpr'
            : 'patient');
        this.tabOneForm.controls.patientFirstName.disable({ onlySelf: true });
        this.tabOneForm.controls.patientType.disable({ onlySelf: true });
        this.tabOneForm.controls.patientLastName.disable({ onlySelf: true });
        this.tabOneForm.controls.registrationDate.disable({ onlySelf: true });
        this.tabOneForm.controls.patientGDPR.disable({ onlySelf: true });
        this.tabOneForm.controls.idDoctor.disable({ onlySelf: true });
        this.allowUseTabs = true;
        this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, 'R').subscribe((resp) => {
            this.returns = resp;
        });
        this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, 'S').subscribe((resp) => {
            this.shippings = resp;
        });
        this._usersService.getAddressById(this.tabOneForm.controls.idDoctor.value, 'I').subscribe((resp) => {
            this.invoices = resp;
        });
    }
    previousPage() {
        this.selectedIndex -= 1;
    }
    nextPage(templateRef = null) {
        if (!this.checkModal) {
            this.checkModal = templateRef;
        }
        this.selectedIndex += 1;
    }
    changeTab(tab, index, templateRef) {
        // it works only if logged-in user is a DOCTOR
        if (this.selectedTreatment !== undefined) {
            if (this.currentUser.role === 'DO' && this.selectedTreatment.status === 'DRAFT' && this.currentUser.showStepsWarning) {
                this.checkModal = templateRef;
                if (!this.isStepsAreValidToMove() && !this.isMovingEvent) {
                    this.stepNotValidModal = this.modalServide.open(this.checkModal, { closeOnNavigation: true });
                }
                else {
                    this.selectedIndex = tab.selectedIndex;
                    this.selectedIndexOld = tab.selectedIndex;
                    this.isMovingEvent = false;
                }
            }
        }
        else {
            this.selectedIndex = tab.selectedIndex;
            this.selectedIndexOld = tab.selectedIndex;
            this.isMovingEvent = false;
        }
    }
    closeValidationNotificationModal() {
        this.stepNotValidModal.close();
        this.selectedIndex = this.selectedIndexOld;
        this.isMovingEvent = true;
    }
    isStepsAreValidToMove() {
        switch (this.selectedIndexOld) {
            case 0:
                return this.tabOneForm.valid;
            case 1:
                return this.tabTwoForm.valid;
            case 2:
                return this.tabThreeForm.valid;
            case 3:
                return this.tabFourForm.valid;
            case 4:
                return this.tabFiveForm.valid;
            case 5:
                return this.tabSixForm.valid;
        }
    }
    acceptValidationNotificationModal() {
        this.stepNotValidModal.close();
        this.selectedIndexOld = this.selectedIndex;
    }
    currentClassTabOne() {
        // return this.showHidePrimaryDangerColor(1);
        return {
            'primary-color': this.tabOneForm.status === 'DISABLED'
                ? !this.tabOneForm.valid
                : this.tabOneForm.valid,
            'danger-color': this.tabOneForm.status === 'DISABLED'
                ? this.tabOneForm.valid
                : !this.tabOneForm.valid
        };
    }
    currentClassTabTwo() {
        if (this.currentUser.role !== 'DO') {
            // NOT A DOCTOR
            if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step2Complete,
                    'danger-color': !this.selectedTreatment.step2Complete
                };
            }
        }
        else {
            // DOCTOR
            return {
                'primary-color': this.tabTwoForm.status === 'DISABLED'
                    ? !this.tabTwoForm.valid
                    : this.tabTwoForm.valid,
                'danger-color': this.tabTwoForm.status === 'DISABLED'
                    ? this.tabTwoForm.valid
                    : !this.tabTwoForm.valid
            };
        }
    }
    currentClassTabThree() {
        if (this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST') {
            if (this.selectedTreatment && this.selectedTreatment.status === 'DRAFT') {
                return {
                    'primary-color': this.tabThreeForm.status === 'DISABLED'
                        ? !this.tabThreeForm.valid
                        : this.tabThreeForm.valid,
                    'danger-color': this.tabThreeForm.status === 'DISABLED'
                        ? this.tabThreeForm.valid
                        : !this.tabThreeForm.valid
                };
            }
            else if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step3Complete,
                    'danger-color': !this.selectedTreatment.step3Complete
                };
            }
        }
        if (this.currentUser.role !== 'DO') {
            // NOT A DOCTOR
            if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step3Complete,
                    'danger-color': !this.selectedTreatment.step3Complete
                };
            }
        }
        else {
            // DOCTOR
            return {
                'primary-color': this.tabThreeForm.status === 'DISABLED'
                    ? !this.tabThreeForm.valid
                    : this.tabThreeForm.valid,
                'danger-color': this.tabThreeForm.status === 'DISABLED'
                    ? this.tabThreeForm.valid
                    : !this.tabThreeForm.valid
            };
        }
    }
    showHidePrimaryDangerColor(stepNumber) {
        let primaryC = false;
        if (this.selectedTreatment !== undefined) {
            // console.log('this.selectedTreatment', this.selectedTreatment.status);
            // console.log('tabOneForm invalid: ', this.tabOneForm.invalid);
            // primaryC = this.selectedTreatment.status == "DRAFT" ? formObject.valid : true;
            let res = false;
            switch (stepNumber) {
                case 1:
                    res = this.step1Valid;
                    break;
                case 2:
                    res = this.step2Valid;
                    break;
                case 3:
                    res = this.step3Valid;
                    break;
                case 4:
                    res = this.step4Valid;
                    break;
                case 5:
                    res = this.step5Valid;
                    break;
                case 6:
                    res = this.step6Valid;
                    break;
            }
            primaryC = res;
        }
        return {
            'primary-color': primaryC,
            'danger-color': !primaryC
        };
    }
    currentClassTabFour() {
        // return this.showHidePrimaryDangerColor(4);
        if (this.currentUser.role !== 'DO') {
            // NOT A DOCTOR
            if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step4Complete,
                    'danger-color': !this.selectedTreatment.step4Complete
                };
            }
        }
        else {
            // DOCTOR
            return {
                'primary-color': this.tabFourForm.status === 'DISABLED'
                    ? !this.tabFourForm.valid
                    : this.tabFourForm.valid,
                'danger-color': this.tabFourForm.status === 'DISABLED'
                    ? this.tabFourForm.valid
                    : !this.tabFourForm.valid
            };
        }
    }
    currentClassTabFive() {
        if (this._router.url === '/newpatient') {
            return {
                'primary-color': false,
                'danger-color': true
            };
        }
        if (this.currentUser.role !== 'DO') {
            // NOT A DOCTOR
            if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step5Complete,
                    'danger-color': !this.selectedTreatment.step5Complete
                };
            }
        }
        else {
            // DOCTOR
            return {
                'primary-color': this.tabFiveForm.status === 'DISABLED'
                    ? !this.tabFiveForm.valid
                    : this.tabFiveForm.valid,
                'danger-color': this.tabFiveForm.status === 'DISABLED'
                    ? this.tabFiveForm.valid
                    : !this.tabFiveForm.valid
            };
        }
    }
    currentClassTabSix() {
        if (this.currentUser.role !== 'DO') {
            // NOT A DOCTOR
            if (this.currentUser && this.selectedTreatment) {
                return {
                    'primary-color': this.selectedTreatment.step6Complete,
                    'danger-color': !this.selectedTreatment.step6Complete
                };
            }
        }
        else {
            // DOCTOR
            return {
                'primary-color': this.tabSixForm.status === 'DISABLED'
                    ? !this.tabSixForm.valid
                    : this.tabSixForm.valid,
                'danger-color': this.tabSixForm.status === 'DISABLED'
                    ? this.tabSixForm.valid
                    : !this.tabSixForm.valid
            };
        }
    }
    dropFileHandle(side, entity) {
        this.tabFourForm.controls[side].setValue(entity.fileName);
        if (!this.fileToBeUploaded.includes(x => x.fileName === entity.fileName)) {
            let propName = entity.fileName.split('.')[0];
            propName = `${propName.charAt(0).toLocaleLowerCase()}${propName.slice(1)}`;
            this.fileToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: propName });
        }
        else {
            for (const file of this.fileToBeUploaded) {
                if (file.fileName === entity.fileName) {
                    file.formData = entity.formData;
                }
            }
        }
        // tslint:disable-next-line:no-unused-expression
        this.tabFourForm.touched;
    }
    dropScanFileHandle(side, entity) {
        this.tabThreeForm.controls[side].setValue(entity.fileName);
        if (!this.fileScansToBeUploaded.includes(x => x.fileName === entity.fileName)) {
            this.fileScansToBeUploaded.push({ fileName: entity.fileName, formData: entity.formData, fieldName: side });
        }
        else {
            for (const file of this.fileScansToBeUploaded) {
                if (file.fileName === entity.fileName) {
                    file.formData = entity.formData;
                }
            }
        }
        // tslint:disable-next-line:no-unused-expression
        this.tabThreeForm.touched;
    }
    onArchesTypeChange(event) {
        switch (event) {
            case 'D':
                this.tabThreeForm.controls.scanLower.setValidators(Validators.required);
                this.tabThreeForm.controls.scanUpper.setValidators(Validators.required);
                this.tabThreeForm.controls.scanLower.setValue(this.selectedTreatment.scanLower);
                this.tabThreeForm.controls.scanUpper.setValue(this.selectedTreatment.scanUpper);
                this.tabTwoForm.controls.return.setValue('-1');
                break;
            case 'T':
                this.tabThreeForm.controls.scanLower.clearValidators();
                this.tabThreeForm.controls.scanUpper.clearValidators();
                this.tabThreeForm.controls.scanLower.reset();
                this.tabThreeForm.controls.scanUpper.reset();
                if (this.tabTwoForm.controls.return.value === '-1') {
                    this.tabTwoForm.controls.return.setValue('null');
                }
                break;
        }
        this.tabThreeForm.controls.archesType.setValue(event);
    }
    assign(obj, prop, value) {
        if (typeof prop === 'string') {
            prop = prop.split('.');
        }
        if (prop.length > 1) {
            const e = prop.shift();
            this.assign(obj[e] =
                Object.prototype.toString.call(obj[e]) === '[object Object]'
                    ? obj[e]
                    : {}, prop, value);
        }
        else {
            obj[prop[0]] = value;
        }
    }
    clearScanFileHandle(side, fileName) {
        // if (!this.fileScansToBeUploaded.find(x => x.fileName.split('.')[0] == fileName)) {
        if (!this.fileScansToBeUploaded.find(x => x.fileName === fileName)) {
            this.assign(this.selectedTreatment, side, null);
            // this.selectedTreatment.scanLower = null;
            this.tabThreeForm.controls[side].setValue(null);
            // tslint:disable-next-line:no-unused-expression
            this.tabThreeForm.touched;
            // this.save(true);
            return;
        }
        // this.fileScansToBeUploaded = this.fileScansToBeUploaded.filter(x => x.fileName.split('.')[0] !== fileName);
        this.fileScansToBeUploaded = this.fileScansToBeUploaded.filter(x => x.fileName !== fileName);
        this.tabThreeForm.controls[side].setValue(null);
        // tslint:disable-next-line:no-unused-expression
        this.tabThreeForm.touched;
        this.cd.detectChanges();
    }
    clearFileHandle(side, fileName) {
        if (!this.fileToBeUploaded.find(x => x.fileName === fileName)) {
            let sideDecoded = '';
            switch (side) {
                case 'upperArch':
                    sideDecoded = 'imageUA';
                    break;
                case 'bottomArch':
                    sideDecoded = 'imageBA';
                    break;
                case 'leftOcclusal':
                    sideDecoded = 'imageLO';
                    break;
                case 'frontOcclusal':
                    sideDecoded = 'imageFO';
                    break;
                case 'rightOcclusal':
                    sideDecoded = 'imageRO';
                    break;
                case 'imageCollage':
                    sideDecoded = 'imageCollage';
                    break;
                case 'leftSide':
                    sideDecoded = 'imageLS';
                    break;
                case 'frontSide':
                    sideDecoded = 'imageFS';
                    break;
                case 'rightSide':
                    sideDecoded = 'imageRS';
                    break;
                case 'rsOrthopanoramics':
                    sideDecoded = 'imageOrtho';
                    break;
                case 'telecranio':
                    sideDecoded = 'imageTelecranio';
                    break;
                case 'other':
                    sideDecoded = 'imageOther';
                    break;
            }
            this.assign(this.selectedTreatment, sideDecoded, null);
            console.log(this.selectedTreatment);
            this.tabFourForm.controls[side].setValue(null);
            // tslint:disable-next-line:no-unused-expression
            this.tabFourForm.touched;
            // this.save(true);
            return;
        }
        this.fileToBeUploaded = this.fileToBeUploaded.filter(x => x.fileName !== fileName);
        this.tabFourForm.controls[side].setValue(null);
        // tslint:disable-next-line:no-unused-expression
        this.tabFourForm.touched;
    }
    downloadFileHandle(fileName) {
        this._contentService.getContentFile(`t-${this.treatmentId}`, fileName).subscribe((resp) => {
            let fileNameToSave = fileName;
            console.log('fileNameToSave', fileNameToSave);
            if (fileName === 'upper_arch_scan.stl') {
                fileNameToSave = this.selectedTreatment.patientFullName.replace(/[^a-z0-9]/gi, '-') + '-Upper.stl';
            }
            if (fileName === 'lower_arch_scan.stl') {
                fileNameToSave = this.selectedTreatment.patientFullName.replace(/[^a-z0-9]/gi, '-') + '-Lower.stl';
            }
            FileSaver(resp, fileNameToSave);
            if (fileName === 'upper_arch_scan.stl') {
                this.stlFileUpper.isDownloadingFile = false;
                this.cd.detectChanges();
            }
            if (fileName === 'lower_arch_scan.stl') {
                this.stlFileLower.isDownloadingFile = false;
                this.cd.detectChanges();
            }
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.errorDownloadFileMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    openFileHandle(fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this._contentService.getContentLink(`t-${this.treatmentId}`, fileName).toPromise();
            const image_window = window.open(resp.fullUrl, resp.fullUrl);
            image_window.document.write(`
     <html>
       <head>
       </head>
       <body>
         <img src="${resp.fullUrl}" width="50%">
       </body>
     </html>`);
        });
    }
    onRadioButtonChange(event) {
        switch (event) {
            case 'patient':
                this.tabOneForm.controls.patientFirstName.setValidators(Validators.required);
                this.tabOneForm.controls.patientLastName.setValidators(Validators.required);
                this.tabOneForm.controls.patientGDPR.clearValidators();
                if (this.selectedTreatment) {
                    this.tabOneForm.controls.patientGDPR.reset();
                    this.tabOneForm.controls.patientFirstName.setValue(this.selectedTreatment.patientFirstName);
                    this.tabOneForm.controls.patientLastName.setValue(this.selectedTreatment.patientLastName);
                }
                else {
                    this.tabOneForm.controls.patientGDPR.setValue(null);
                }
                break;
            case 'gdpr':
                this.tabOneForm.controls.patientFirstName.clearValidators();
                this.tabOneForm.controls.patientLastName.clearValidators();
                this.tabOneForm.controls.patientGDPR.setValidators(Validators.required);
                if (this.selectedTreatment) {
                    this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
                    this.tabOneForm.controls.patientFirstName.reset();
                    this.tabOneForm.controls.patientLastName.reset();
                }
                else {
                    this.tabOneForm.controls.patientFirstName.setValue(null);
                    this.tabOneForm.controls.patientLastName.setValue(null);
                }
                break;
        }
        this.tabOneForm.controls.patientType.setValue(event);
    }
    onCheckBoxChange(checked) {
        if (checked) {
            this.upperTeeth = [];
            this.lowerTeeth = [];
        }
        else {
            this.prepareTeeth();
        }
    }
    // onChangeRelationShip(checked) {
    //   if (checked) {
    //     this.tabFiveForm.controls.anteriorTreatment.clearValidators();
    //     this.tabFiveForm.controls.anteriorTreatment.setErrors(null);
    //     this.tabFiveForm.controls.anteriorTreatment.setValue(this.tabFiveForm.controls.anteriorTreatment.value);
    //     return;
    //   }
    //   this.tabFiveForm.controls.anteriorTreatment.setValidators([customFalseValidator()]);
    //   this.tabFiveForm.controls.anteriorTreatment.setValue(this.tabFiveForm.controls.anteriorTreatment.value);
    // }
    onChangeAestheticTreatment(checked) {
        if (checked) {
            this.tabFiveForm.controls.changeClass.clearValidators();
            this.tabFiveForm.controls.changeClass.setValue(this.tabFiveForm.controls.changeClass.value);
            return;
        }
        else if (!checked && !this.tabFiveForm.controls.changeClass.value) {
            // this.tabFiveForm.controls.anteriorTreatment.setErrors({ 'required': true })
        }
        //  this.tabFiveForm.controls.changeClass.setValidators([customFalseValidator()]);
        // this.tabFiveForm.controls.changeClass.setValue(null);
    }
    onSpecialInstructioCheckBox(checked) {
        if (checked) {
            this.tabSixForm.controls.specialInstruction.clearValidators();
            this.tabSixForm.controls.specialInstruction.disable({ onlySelf: true });
        }
        else {
            this.tabSixForm.controls.specialInstruction.setValidators([Validators.required]);
            this.tabSixForm.controls.specialInstruction.enable({ onlySelf: true });
        }
        this.tabSixForm.controls.specialInstruction.reset();
    }
    relationshipTrigger(event) {
        if (event === 'changeClass') {
            this.tabFiveForm.controls.typeOfElastic.setValidators([Validators.required]);
        }
        else {
            this.tabFiveForm.controls.typeOfElastic.clearValidators();
        }
        this.tabFiveForm.controls.typeOfElastic.setValue(this.tabFiveForm.controls.typeOfElastic.value);
    }
    onCrowdingUpperSelect(event) {
        // if (event) {
        //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValidators([Validators.required]);
        // } else {
        //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setErrors(null);
        //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.clearValidators();
        //   this.tabFiveForm.controls.crowdingUpperArchWorkflow.setValue([]);
        // }
        // this.tabFiveForm.controls.crowdingUpperArchWorkflow.updateValueAndValidity();
    }
    compareFn(c1, c2) {
        return c1 === c2;
    }
    onCrowdingLowerSelect(event) {
        // if (event) {
        //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValidators([Validators.required]);
        // } else {
        //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setErrors(null)
        //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.clearValidators();
        //   this.tabFiveForm.controls.crowdingLowerArchWorkflow.setValue([]);
        // }
        // this.tabFiveForm.controls.crowdingLowerArchWorkflow.updateValueAndValidity();
    }
    isAllStepsValid() {
        return this.tabOneForm.valid &&
            this.tabTwoForm.valid &&
            this.tabThreeForm.valid &&
            this.tabFourForm.valid &&
            this.tabFiveForm.valid &&
            this.tabSixForm.valid;
    }
    isSendButtonEnabled() {
        // tslint:disable-next-line:max-line-length
        return this.selectedTreatment && (this.selectedTreatment.status === 'DRAFT' || this.selectedTreatment.status === 'INCOMPLETE') && this.isAllStepsValid();
    }
    createTreatment(tempalteRef) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.tabOneForm.valid) {
                this.tabOneForm.markAllAsTouched();
                return;
            }
            this.isLoading = true;
            const request = Object.assign({}, this.tabOneForm.value);
            if (!request.registrationDate) {
                request.registrationDate = this.tabOneForm.controls.registrationDate.value;
            }
            if (!request.idDoctor && this.currentUser.role === 'DO') {
                request.idDoctor = this.currentUser.idAdmin;
            }
            if (!request.patientFirstName || !request.patientLastName) {
                request.patientFirstName = '';
                request.patientLastName = '';
            }
            if (this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST') {
                // STRAT USER => upload also STL files (if found)
                request.scanLower = this.tabThreeForm.controls.scanLower.value;
                request.scanUpper = this.tabThreeForm.controls.scanUpper.value;
            }
            this._patientService.createTreatment(request).subscribe((resp) => {
                if (resp && this.currentUser.role === 'DO') {
                    this.isLoading = false;
                    this._router.navigate(['/treatments', resp.idTreatment], { queryParams: { step: 2 } });
                    return;
                }
                if (this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST') {
                    // STRAT USER => upload also STL files (if found)
                    // upload files if found
                    let isError = false;
                    if (this.fileScansToBeUploaded.length > 0) {
                        for (const file of this.fileScansToBeUploaded) {
                            try {
                                this._contentService.uploadContentBlob(file.formData, `t-${resp.idTreatment}`, true, 'D').toPromise();
                            }
                            catch (_a) {
                                isError = true;
                                continue;
                            }
                        }
                        if (isError) {
                            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                                duration: 3000,
                                panelClass: 'warning',
                                horizontalPosition: 'right',
                                verticalPosition: 'top',
                            });
                            return;
                        }
                    }
                }
                this.isLoading = false;
                this.snackBar.open(this._translocoService.translate('patient.createdSuccessful'), 'X', {
                    duration: 3000,
                    panelClass: 'success',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
                this._router.navigate(['/treatments']);
            }, (error) => {
                if (error.status === 409) {
                    this.isLoading = false;
                    this.notFoundModal = this.modalServide.open(tempalteRef, { closeOnNavigation: true });
                    return;
                }
                this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                    duration: 3000,
                    panelClass: 'warning',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            });
        });
    }
    closeNotFoundModal() {
        this.notFoundModal.close();
    }
    closeAdditionButtonModal() {
        this.additionalModal.close();
    }
    closeIncompleteModal() {
        this.incompleteModal.close();
    }
    onChangePatientDataBoxChange(val) {
        if (val) {
            this.tabOneForm.controls.patientFirstName.enable({ onlySelf: true });
            this.tabOneForm.controls.patientType.enable({ onlySelf: true });
            this.tabOneForm.controls.patientLastName.enable({ onlySelf: true });
            this.tabOneForm.controls.patientGDPR.enable({ onlySelf: true });
            return;
        }
        this.tabOneForm.controls.patientFirstName.setValue(this.tabOneForm.controls.patientFirstName.value);
        this.tabOneForm.controls.patientLastName.setValue(this.tabOneForm.controls.patientLastName.value);
        this.tabOneForm.controls.patientGDPR.setValue(this.selectedTreatment.patientGDPR);
        this.tabOneForm.controls.patientFirstName.disable({ onlySelf: true });
        this.tabOneForm.controls.patientType.disable({ onlySelf: true });
        this.tabOneForm.controls.patientLastName.disable({ onlySelf: true });
        this.tabOneForm.controls.patientGDPR.disable({ onlySelf: true });
    }
    saveVideoRequested() {
        this._patientService.saveVideoRequested(this.treatmentId, this.tabSixForm.controls.videoRequested.value).subscribe(resp => {
            if (resp) {
                this.snackBar.open(this._translocoService.translate('patient.updatedSuccessful'), 'X', {
                    duration: 3000,
                    panelClass: 'success',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            }
        });
    }
    updatePatientData() {
        if (!this.tabOneForm.valid) {
            this.tabOneForm.controls.patientFirstName.markAsTouched({ onlySelf: true });
            this.tabOneForm.controls.patientLastName.markAsTouched({ onlySelf: true });
            this.tabOneForm.controls.patientGDPR.markAsTouched({ onlySelf: true });
            return;
        }
        const request = {
            patientFirstName: this.tabOneForm.controls.patientFirstName.value,
            patientLastName: this.tabOneForm.controls.patientLastName.value,
            patientGDPR: this.tabOneForm.controls.patientGDPR.value,
            idTreatment: +this.treatmentId
        };
        this._patientService.updatePatientData(request).subscribe(resp => {
            if (resp) {
                this.prepareTreatmentCall();
                this.tabOneForm.controls.changeCheckBox.setValue(false);
                this.snackBar.open(this._translocoService.translate('patient.updatedSuccessful'), 'X', {
                    duration: 3000,
                    panelClass: 'success',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            }
        });
    }
    getCurrentOptTittle(opt) {
        switch (this._translocoService.getActiveLang()) {
            case 'en':
                return opt.optionTitle_EN;
            case 'it':
                return opt.optionTitle_IT;
        }
    }
    save(reload, ref) {
        this.isLoading = reload;
        this.request = {
            'idTreatment': +this.treatmentId,
            // tslint:disable-next-line:max-line-length
            'archesHandled': this.tabThreeForm.controls.archesTypeHandle.value === 'null' ? 'N' : this.tabThreeForm.controls.archesTypeHandle.value,
            'archesType': this.tabThreeForm.controls.archesType.value == null ? 'N' : this.tabThreeForm.controls.archesType.value,
            'byteRamp': this.tabFiveForm.controls.biteRamp.value === 'null' ? false : this.tabFiveForm.controls.biteRamp.value,
            'youngOpSystem': this.tabThreeForm.controls.youngOpSystem.value,
            'youngThroughValue': this.tabFiveForm.controls.youngThroughValue.value,
            'youngCase': this.tabFiveForm.controls.youngCase.value,
            'youngDeepValues': this.tabFiveForm.controls.youngDeepValues.value,
            'youngOpenValues': this.tabFiveForm.controls.youngOpenValues.value,
            'youngMolar': this.tabFiveForm.controls.youngMolar.value,
            'youngIPRUpper': this.tabFiveForm.controls.youngIPRUpper.value,
            'youngIPRLower': this.tabFiveForm.controls.youngIPRLower.value,
            'youngEndResult': this.tabFiveForm.controls.youngEndResult.value,
            'youngElasticMethod': this.tabFiveForm.controls.youngElasticMethod.value,
            'youngLimitIncisors': this.tabFiveForm.controls.youngLimitIncisors.value,
            // tslint:disable-next-line:max-line-length
            'crowdingLowerArch': this.tabFiveForm.controls.crowdingLowerArch.value === 'null' ? false : this.tabFiveForm.controls.crowdingLowerArch.value,
            // tslint:disable-next-line:max-line-length
            // "crowdingLowerArchType": this.tabFiveForm.controls.crowdingLowerArchWorkflow.value == 'null' ? "N" : this.tabFiveForm.controls.crowdingLowerArchWorkflow.value,
            // tslint:disable-next-line:max-line-length
            'crowdingLowerArchType': (this.tabFiveForm.controls.crowdingLowerArchWorkflow.value === false || this.tabFiveForm.controls.crowdingLowerArchWorkflow.value == null) ? [] : this.tabFiveForm.controls.crowdingLowerArchWorkflow.value,
            // tslint:disable-next-line:max-line-length
            'crowdingUpperArch': this.tabFiveForm.controls.crowdingUpperArch.value === 'null' ? false : this.tabFiveForm.controls.crowdingUpperArch.value,
            // tslint:disable-next-line:max-line-length
            // "crowdingUpperArchType": this.tabFiveForm.controls.crowdingUpperArchWorkflow.value == 'null' ? "N" : this.tabFiveForm.controls.crowdingUpperArchWorkflow.va
            // tslint:disable-next-line:max-line-length
            'crowdingUpperArchType': (this.tabFiveForm.controls.crowdingUpperArchWorkflow.value === false || this.tabFiveForm.controls.crowdingUpperArchWorkflow.value == null) ? [] : this.tabFiveForm.controls.crowdingUpperArchWorkflow.value,
            'elasticType': this.tabFiveForm.controls.typeOfElastic.value,
            'idAddressInvoice': this.tabTwoForm.controls.invoice.value === 'null' ? 0 : +this.tabTwoForm.controls.invoice.value,
            'idAddressReturn': this.tabTwoForm.controls.return.value === 'null' ? 0 : +this.tabTwoForm.controls.return.value,
            'idAddressShipping': this.tabTwoForm.controls.ship.value === 'null' ? 0 : +this.tabTwoForm.controls.ship.value,
            'imageLS': this.tabFourForm.controls.leftSide.value,
            'youngMoveUpperMidline': this.tabFiveForm.controls.youngMoveUpperMidline.value,
            'youngMoveLowerMidline': this.tabFiveForm.controls.youngMoveLowerMidline.value,
            'youngMoveUpperMidlineSide': this.tabFiveForm.controls.youngMoveUpperMidlineSide.value,
            'youngMoveLowerMidlineSide': this.tabFiveForm.controls.youngMoveLowerMidlineSide.value,
            'imageFS': this.tabFourForm.controls.frontSide.value,
            'imageRS': this.tabFourForm.controls.rightSide.value,
            'imageBA': this.tabFourForm.controls.bottomArch.value,
            'imageFO': this.tabFourForm.controls.frontOcclusal.value,
            'imageLO': this.tabFourForm.controls.leftOcclusal.value,
            'imageOrtho': this.tabFourForm.controls.rsOrthopanoramics.value,
            'imageTelecranio': this.tabFourForm.controls.telecranio.value,
            'imageOther': this.tabFourForm.controls.other.value,
            'imageRO': this.tabFourForm.controls.rightOcclusal.value,
            'imageUA': this.tabFourForm.controls.upperArch.value,
            'imageType': this.tabFourForm.controls.imageType.value,
            'imageCollage': this.tabFourForm.controls.imageCollage.value,
            'lowerArchDiastema': this.tabFiveForm.controls.lowerArchDiastema.value ? this.tabFiveForm.controls.lowerArchDiastema.value : 'N',
            'noSpecialInstruction': this.tabSixForm.controls.noSpecialInstruction.value,
            'noTeethToExclude': this.tabFiveForm.controls.noExcludeTeeth.value,
            'changeClass': this.tabFiveForm.controls.changeClass.value,
            // "anteriorTreatment": this.tabFiveForm.controls.anteriorTreatment.value,
            // "overJet": this.tabFiveForm.controls.overJet.value,
            // "overByte": this.tabFiveForm.controls.overByte.value,
            'overJetType': this.tabFiveForm.controls.overJetType.value == null ? 'N' : this.tabFiveForm.controls.overJetType.value,
            'overByteType': this.tabFiveForm.controls.overByteType.value == null ? 'N' : this.tabFiveForm.controls.overByteType.value,
            'crossBiteType': this.tabFiveForm.controls.crossBiteType.value == null ? 'N' : this.tabFiveForm.controls.crossBiteType.value,
            'patientType': this.tabThreeForm.controls.patientAgeType.value === 'null' ? 'N' : this.tabThreeForm.controls.patientAgeType.value,
            'archesSentViaCommunicator': this.tabThreeForm.controls.archesSentViaCommunicator.value,
            'specialInstruction': this.tabSixForm.controls.specialInstruction.value,
            'teethToExclude': [
                ...this.upperTeeth.filter(x => x.isSelected).map(x => x.id),
                ...this.lowerTeeth.filter(x => x.isSelected).map(x => x.id)
            ],
            // tslint:disable-next-line:max-line-length
            // "treatmentAction": this.tabFiveForm.controls.objectiveRadioButton.value ? this.tabFiveForm.controls.objectiveRadioButton.value : "N",
            'treatmentType': this.tabThreeForm.controls.patientType.value === 'null' ? 'NO' : this.tabThreeForm.controls.patientType.value,
            'clinicProject': this.tabThreeForm.controls.clinicProject.value,
            'upperArchDiastema': this.tabFiveForm.controls.upperArchDiastema.value ? this.tabFiveForm.controls.upperArchDiastema.value : 'N',
            'scanLower': this.tabThreeForm.controls.scanLower.value,
            'scanUpper': this.tabThreeForm.controls.scanUpper.value,
            'videoRequested': this.tabSixForm.controls.videoRequested.value,
            'phase': this.selectedTreatment.phase,
            'phaseType': this.selectedTreatment.phaseType
        };
        // for (let file of this.fileToBeUploaded) {
        //   try {
        //     this.reload = true;
        //     //this.isLoading = true;
        //     //await this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true).toPromise();
        //     this.progressUpload = 0;
        //     this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true).subscribe((event: HttpEvent<any>) => {
        //       switch (event.type) {
        //         case HttpEventType.Sent:
        //           console.log('Request has been made!');
        //           break;
        //         case HttpEventType.ResponseHeader:
        //           console.log('Response header has been received!');
        //           break;
        //         case HttpEventType.UploadProgress:
        //           this.progressUpload = Math.round(event.loaded / event.total * 100);
        //           console.log(`Uploaded! ${this.progressUpload}%`);
        //           break;
        //         case HttpEventType.Response:
        //           console.log('File successfully created!', event.body);
        //       }
        //       this.cd.detectChanges();
        //     });
        //     this.request = this.updateRequest(this.request, this.tabFourForm.controls[file.fieldName].value, file.fieldName);
        //   } catch {
        //     this.request = this.updateRequest(this.request, "", file.fieldName);
        //     this.isError = true;
        //     continue;
        //   }
        // }
        this.fileToBeUploadedMerged = [...this.fileToBeUploaded, ...this.fileScansToBeUploaded];
        if (this.fileToBeUploadedMerged.length !== 0 && ref != null) {
            this.fileLoadProgressModal = this.modalServide.open(ref, { closeOnNavigation: true, disableClose: true, panelClass: 'lg-modal' });
            this.uploadFileScanc(this.fileToBeUploadedMerged[0], this.tabThreeForm.controls.archesType.value);
        }
        if (this.fileToBeUploadedMerged.length === 0) {
            this.updateTreatmentAfterFileUpload(this.request, this.isError, this.reload);
        }
    }
    round(value, precision) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
    sendChatMessageAndSave(reload, ref) {
        const message = this._translocoService.translate('patient.GENERAL_INFO.chatMessageForOptionalImages');
        this.chatService.sendMessage(+this.treatmentId, message, 'T').subscribe(resp => {
            // send message to update the chat
            this._messagingService.sendMessage('refreshTreatmentChatMessages');
            this.save(reload, ref);
        });
    }
    changeStatus() {
        this.isLoading = true;
        this.closeChangeStatusModal();
        this.planningService.assignedTo({
            'assignedTo': 0,
            'done': true,
            'idTreatment': this.selectedTreatment.idTreatment,
            'userGroup': 'SH',
            'jobType': 'T',
            'idRequest': 0,
            'videoRequested': false,
            'estimatedDate': null
        }).subscribe(res => {
            this.isLoading = false;
            this.cd.detectChanges();
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this._router.navigate(['/treatments', this.treatmentId]);
            });
        });
    }
    closeChangeStatusModal() {
        this.changeStatusModal.close();
    }
    changeStatusPrompt(ref) {
        this.changeStatusModal = this.modalServide.open(ref, { closeOnNavigation: true });
    }
    uploadFileScanc(file, archesType) {
        this.progressUploadSaving = false;
        this.progressUpload = 0;
        ++this.currentFileIterator;
        this.reload = true;
        this.isUploading = true;
        let percentDone;
        let currTime;
        let prevTime;
        let bytesReceied = 0;
        let oldbytes = 0;
        // setting start time
        this.uploadSub = this._contentService.uploadContentBlob(file.formData, `t-${this.treatmentId}`, true, archesType).subscribe({
            next: (event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        // upload speed
                        percentDone = Math.round((100 * event.loaded) / event.total);
                        currTime = new Date().getTime();
                        // tracking how much data is received
                        bytesReceied = event.loaded / 1000000;
                        // calculating download speed per percent data received
                        this.speedUpload = (bytesReceied - oldbytes) / ((currTime - prevTime) / 1000);
                        if (this.speedUpload < 1) {
                            this.unitUpload = 'Kbps';
                            this.speedUpload *= 1000;
                        }
                        else {
                            this.unitUpload = 'Mbps';
                        }
                        // updating previous values
                        prevTime = currTime;
                        oldbytes = bytesReceied;
                        this.speedUpload = Math.round(this.speedUpload);
                        console.log('speed: ' + this.speedUpload + ' ' + this.unitUpload);
                        // if (percentDone > 1) {
                        //   endTime = new Date().getTime();
                        //   let duration = (endTime - startTime) / 1000;
                        //   let mbps = event.total / duration / 1000000;
                        //   if (mbps < 1) {
                        //     speed = event.total / duration / 1000;
                        //     unit = "Kbps";
                        //   } else {
                        //     speed = mbps;
                        //     unit = "Mbps";
                        //   }
                        //   console.log('speed: ' + speed + ' ' + unit);
                        // }
                        // this.progressUpload = Math.round(event.loaded / event.total * 100);
                        this.progressUpload = this.round(event.loaded / event.total * 100, 1);
                        // console.log(`Uploaded! ${this.progressUpload}%`);
                        this.progressUploadSaving = this.progressUpload === 100;
                        break;
                    case HttpEventType.Response:
                        console.log('File successfully created!', event.body);
                        this.progressUploadSaving = false;
                        break;
                }
            }, error: () => {
                this.request[file.fieldName] = '';
                this.isError = true;
                this.isUploading = false;
            }, complete: () => {
                this.isUploading = false;
                if (file.fieldName === 'scanLower' || file.fieldName === 'scanUpper') {
                    // step 3
                    this.request[file.fieldName] = this.tabThreeForm.controls[file.fieldName].value;
                }
                else {
                    // step 4
                    this.request[file.fieldName] = this.tabFourForm.controls[file.fieldName].value;
                }
                if (this.fileToBeUploadedMerged.indexOf(file) !== this.fileToBeUploadedMerged.length - 1) {
                    this.uploadFileScanc(this.fileToBeUploadedMerged[this.fileToBeUploadedMerged.indexOf(file) + 1], archesType);
                    return;
                }
                this.updateTreatmentAfterFileUpload(this.request, this.isError, this.reload);
            }
        });
    }
    awaitBeforeUploaded() {
        if (this.isUploading) {
            this.awaitBeforeUploaded();
        }
    }
    updateTreatmentAfterFileUpload(request, isError, reload) {
        this.fileToBeUploaded = [];
        this.fileScansToBeUploaded = [];
        this.fileToBeUploadedMerged = [];
        this.currentFileIterator = 0;
        this._patientService.saveTreatment(request).subscribe((resp) => {
            if (isError) {
                this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                    duration: 3000,
                    panelClass: 'warning',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            }
            else {
                this.snackBar.open(this._translocoService.translate('patient.saveTreatmentSuccessful'), 'X', {
                    duration: 3000,
                    panelClass: 'success',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            }
            this.selectedTreatment.steps = resp.steps;
            this.selectedTreatment.step1Complete = resp.step1Complete;
            this.selectedTreatment.step2Complete = resp.step2Complete;
            this.selectedTreatment.step3Complete = resp.step3Complete;
            this.selectedTreatment.step4Complete = resp.step4Complete;
            this.selectedTreatment.step5Complete = resp.step5Complete;
            this.selectedTreatment.step6Complete = resp.step6Complete;
            if (this.SaveAndSend) {
                this.SaveAndSend = false;
                // update status to EVALUATION
                this._patientService.changeStatus(null, this.treatmentId, 'evaluation').subscribe(() => {
                    // this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
                    //   duration: 3000,
                    //   panelClass: "success",
                    //   horizontalPosition: "right",
                    //   verticalPosition: "top",
                    // } as MatSnackBarConfig);
                    this.closeAdditionButtonModal();
                    // update status => in this wasy prevent the modal of exit without SEND
                    this.selectedTreatment.status = 'EVALUATION';
                    // redirect to dashboard
                    this._router.navigate(['/dashboard']);
                }, error => {
                    this.closeAdditionButtonModal();
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                });
            }
            // tslint:disable-next-line:max-line-length
            if (this.currentUser.role === 'DO' && (this.selectedTreatment.status === 'INPROJECT' || this.selectedTreatment.status === 'INREVIEW')) {
                // this.selectedTreatment.imageTelecranio = 'telecranio.png';
                this.EmptyFile = null;
            }
            // //IF IT'S A DOCTOR, ON STEP 4 => SEND CHAT MESSAGE
            // tslint:disable-next-line:max-line-length
            // if ((this.selectedTreatment.status == 'INPROJECT' || this.selectedTreatment.status == 'INREVIEW') && this.currentUser.role == 'DO' && this.selectedIndex == 3) {
            //   var message = this._translocoService.translate("patient.GENERAL_INFO.chatMessageForOptionalImages");
            //   let chatMessage = this.chatService.sendMessage(+this.treatmentId, message, false).subscribe();
            // }
            this.isLoading = false;
            if (reload) {
                this._router.navigate(['/treatments', this.treatmentId]);
                // setTimeout(() => {
                //   this.prepareTreatmentCall();
                // }, 0);
            }
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
        if (this.fileLoadProgressModal.close != null) {
            this.fileLoadProgressModal.close();
        }
        setTimeout(() => {
            this.cd.detectChanges();
        }, 100);
    }
    updateRequest(request, fileName, fieldName) {
        switch (fieldName) {
            case 'leftSide':
                request.imageLS = fileName;
                break;
            case 'frontSide':
                request.imageFS = fileName;
                break;
            case 'rightSide':
                request.imageRS = fileName;
                break;
            case 'bottomArch':
                request.imageBA = fileName;
                break;
            case 'frontOcclusal':
                request.imageFO = fileName;
                break;
            case 'leftOcclusal':
                request.imageLO = fileName;
                break;
            case 'rsOrthopanoramics':
                request.imageOrtho = fileName;
                break;
            case 'telecranio':
                request.imageTelecranio = fileName;
                break;
            case 'other':
                request.imageOther = fileName;
                break;
            case 'rightOcclusal':
                request.imageRO = fileName;
                break;
            case 'upperArch':
                request.imageUA = fileName;
                break;
        }
        return request;
    }
    openRecoverFromIncompleteModal(type, ref, refInfo = null) {
        this.selectedModalType = type;
        switch (type) {
            case 'recoverTreatment':
                this.modalMessage = 'patient.ADDITIONAL.recoverMessage';
                this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
                break;
            case 'draft':
                this.draftReason = new FormControl(null);
                this.modalMessage = 'patient.InReviewReasonDoctor';
                this.confirmModal = this.modalServide.open(ref, { panelClass: 'lg-modal', closeOnNavigation: true });
                break;
            case 'deleteVideo':
                this.modalMessage = 'patient.ADDITIONAL.deleteVideoMessage';
                this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
                break;
            case 'generateTreatmentCode':
                if (this.tabThreeForm.controls.archesType.value == null) {
                    this.modalMessage = 'patient.GENERAL_INFO.generateTreatmentCodeWarningMessage';
                    this.infoModal = this.modalServide.open(refInfo, { closeOnNavigation: true });
                }
                else {
                    this.modalMessage = 'patient.GENERAL_INFO.generateTreatmentCodeMessage';
                    this.confirmModal = this.modalServide.open(ref, { closeOnNavigation: true });
                }
                break;
            default:
                return;
        }
    }
    showUpdateStatusModal(ref) {
        this.updateStatusModal = this.modalServide.open(ref, { closeOnNavigation: true });
    }
    closeUpdateStatusModal() {
        this.updateStatusModal.close();
    }
    updateTreatmentStatus() {
        // update treatment status
        this.isUpdatieStillLoading = true;
        this._patientService.changeStatus('', this.treatmentId, this.updateStatusNewValue).subscribe(resp => {
            this.snackBar.open(this._translocoService.translate('patient.statusChangedSuccessfull'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isUpdatieStillLoading = false;
            this.closeUpdateStatusModal();
            setTimeout(() => {
                this.prepareTreatmentCall();
            }, 0);
        });
    }
    closeRecoverFromIncompleteModal() {
        this.confirmModal.close();
    }
    closeImagesPreviewModal() {
        this.imagePreview.close();
    }
    closeDoctorAdditionalInfoModal() {
        this.DoctorAdditionalInfo.close();
    }
    closePatientAdditionalInfoModal() {
        this.PatientAdditionalInfo.close();
    }
    _filter(value, items) {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        // tslint:disable-next-line:max-line-length
        return items.filter(option => option.firstName.toLowerCase().indexOf(filterValue) > -1 ||
            option.lastName.toLowerCase().indexOf(filterValue) > -1 ||
            option.fullName.toLowerCase().indexOf(filterValue) > -1);
    }
    fillDoctors() {
        this.filteredDoctors = this.DoctorsCtrl.valueChanges.pipe(startWith(''), map(value => this._filter(value || '', this.doctorsArray)));
    }
    changeDoctor() {
        this.isLoading = true;
        const request = Object.assign({}, this.changeDoctorForm.value, { idPreviousDoctor: this.previousDoctorId });
        this.treatmentsService.changeDoctor(request).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('patient.PATIENT.clinicPreferencesUpdated'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.closeDoctorModal();
            // refresh current treatment
            setTimeout(() => {
                this.prepareTreatmentCall();
            }, 0);
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.closeDoctorModal();
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    disableChangeDoctorSubmit() {
        return !this.changeDoctorForm.valid;
    }
    showChangeButton() {
        return this.currentUser && this.currentUser.userGroup === 'AD'
            && this.currentUser.role === 'US' && this.selectedTreatment &&
            this.selectedTreatment.status !== 'SHIPPED' &&
            this.selectedTreatment.status !== 'MIGRATED' &&
            this.selectedTreatment.status !== 'ARCHIVED' && this.selectedTreatment.treatmentCode !== '----------';
    }
    showCloneButton() {
        return this.currentUser &&
            (this.currentUser.userGroup === 'ST' || this.currentUser.userGroup === 'ED') &&
            this.selectedTreatment &&
            (this.selectedTreatment.status === 'EVALUATION' || this.selectedTreatment.status === 'INPROJECT') &&
            // tslint:disable-next-line:triple-equals
            this.selectedTreatment.phase == '1' && this.selectedTreatment.phaseType === 'P';
    }
    cloneTreatment() {
        this.isLoading = true;
        const request = Object.assign({}, this.cloneTreatmentForm.value);
        this.treatmentsService.cloneTreatment(request).subscribe(resp => {
            this.isLoading = false;
            if (resp === 0) {
                this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                    duration: 3000,
                    panelClass: 'warning',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
                this.closeCloneTreatmentModal();
                this.isLoading = false;
                this.cd.detectChanges();
                return false;
            }
            this.snackBar.open(this._translocoService.translate('patient.PATIENT.clinicPreferencesUpdated'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.closeCloneTreatmentModal();
            // redirect to new treatment
            setTimeout(() => {
                this._router.navigate(['/treatments', resp]);
            }, 0);
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.closeCloneTreatmentModal();
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    showCloneDoctorModal(ref) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.superAgents = (yield this._usersService.getByRole('SA').toPromise());
            this.distributors = (yield this._usersService.getByRole('DI').toPromise());
            this.agents = (yield this._usersService.getByRole('AG').toPromise());
            this.doctorsArray = (yield this._usersService.getByRole('DO').toPromise());
            this.cloneTreatmentForm = this.fb.group({
                previousDoctorLastName: [null, Validators.required],
                previousPatientLastName: [null, Validators.required],
                idTreatment: [null, Validators.required],
                idSuperAgent: [null, Validators.required],
                idDistributor: [null, Validators.required],
                idAgent: [null, Validators.required],
                idDoctor: [null, Validators.required],
            });
            if (this.selectedTreatment) {
                this.cloneTreatmentForm.controls.idTreatment.setValue(this.selectedTreatment.idTreatment);
                this.cloneTreatmentForm.controls.previousPatientLastName.setValue(this.selectedTreatment.patientLastName ||
                    this.selectedTreatment.patientGDPR);
            }
            const currentDoctor = this.doctorsArray.find((doc) => doc.idAdmin === this.selectedTreatment.idDoctor);
            if (currentDoctor) {
                this.cloneTreatmentForm.controls.previousDoctorLastName.setValue(currentDoctor.lastName);
            }
            this.fillDoctors();
            this.CloneTreatmentModal = this.modalServide.open(ref, {
                closeOnNavigation: true,
                panelClass: 'lg-modal-doctor-change',
                autoFocus: false
            });
        });
    }
    closeCloneTreatmentModal() {
        this.CloneTreatmentModal.close();
    }
    disableCloneTreatmentSubmit() {
        return !this.cloneTreatmentForm.valid;
    }
    clearDoctor(form) {
        this.DoctorsCtrl.setValue(null);
        this.SuperAgentName.setValue(null);
        this.DistributorName.setValue(null);
        this.AgentName.setValue(null);
        form.controls.idSuperAgent.setValue(null);
        form.controls.idDistributor.setValue(null);
        form.controls.idAgent.setValue(null);
        form.controls.idDoctor.setValue(null);
    }
    setUsers(value, form) {
        if (value.idSuperAgent) {
            form.controls.idSuperAgent.setValue(value.idSuperAgent);
        }
        if (value.idDistributor) {
            form.controls.idDistributor.setValue(value.idDistributor);
        }
        if (value.idAgent) {
            form.controls.idAgent.setValue(value.idAgent);
        }
        form.controls.idDoctor.setValue(value.idAdmin);
        const superAgent = this.superAgents.find(item => item.idAdmin === value.idSuperAgent);
        const distributor = this.distributors.find(item => item.idAdmin === value.idDistributor);
        const agent = this.agents.find(item => item.idAdmin === value.idAgent);
        if (superAgent) {
            this.SuperAgentName.setValue(`${superAgent.lastName} ${superAgent.firstName}`);
        }
        else {
            this.SuperAgentName.setValue('');
        }
        if (distributor) {
            this.DistributorName.setValue(`${distributor.lastName} ${distributor.firstName}`);
        }
        else {
            this.DistributorName.setValue('');
        }
        if (agent) {
            this.AgentName.setValue(`${agent.lastName} ${agent.firstName}`);
        }
        else {
            this.AgentName.setValue('');
        }
    }
    showHistoryButton() {
        return this.currentUser && this.currentUser.role === 'EX';
    }
    closeHistoryButton() {
        return this.HistoryDoctorModal.close();
    }
    getRowClass() {
        return {
            'fullWidth': true
        };
    }
    showHistoryModal(ref) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.gridColumns = [
                {
                    name: this._translocoService.translate('patient.GENERAL_INFO.historyPrevDoctorLabel'),
                    prop: 'previousDoctorFullName',
                    headerTemplate: this.hdrTpl,
                },
                {
                    name: this._translocoService.translate('patient.GENERAL_INFO.historyNewDoctorLabel'),
                    prop: 'doctorFullName',
                    headerTemplate: this.hdrTpl,
                },
                {
                    name: this._translocoService.translate('patient.GENERAL_INFO.historyOperatorLabel'),
                    prop: 'userFullName',
                    headerTemplate: this.hdrTpl,
                },
                {
                    name: this._translocoService.translate('patient.GENERAL_INFO.historyDateLabel'),
                    prop: 'logDate',
                    headerTemplate: this.hdrTpl,
                    cellTemplate: this.cellDate
                },
            ];
            this.doctorsHistory = (yield this.treatmentsService.getDoctorHistory(this.selectedTreatment.idTreatment).toPromise());
            // tslint:disable-next-line:max-line-length
            this.HistoryDoctorModal = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: 'lg-modal-doctor-change', autoFocus: false });
        });
    }
    showChangeDoctorModal(ref) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.superAgents = (yield this._usersService.getByRole('SA').toPromise());
            this.distributors = (yield this._usersService.getByRole('DI').toPromise());
            this.agents = (yield this._usersService.getByRole('AG').toPromise());
            this.doctorsArray = (yield this._usersService.getByRole('DO').toPromise());
            this.changeDoctorForm = this.fb.group({
                idTreatment: [null, Validators.required],
                idSuperAgent: [null, Validators.required],
                idDistributor: [null, Validators.required],
                idAgent: [null, Validators.required],
                idDoctor: [null, Validators.required],
            });
            if (this.selectedTreatment) {
                const doctor = this.doctorsArray.find(item => item.idAdmin === this.selectedTreatment.idDoctor);
                if (doctor) {
                    this.DoctorsCtrl.setValue(doctor);
                }
                this.changeDoctorForm.controls.idTreatment.setValue(this.selectedTreatment.idTreatment);
                this.setUsers(this.selectedTreatment, this.changeDoctorForm);
                this.previousDoctorId = this.selectedTreatment.idDoctor;
                this.changeDoctorForm.controls.idDoctor.setValue(this.selectedTreatment.idDoctor);
            }
            this.fillDoctors();
            // tslint:disable-next-line:max-line-length
            this.ChangeDoctorModal = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: 'lg-modal-doctor-change', autoFocus: false });
        });
    }
    closeDoctorModal() {
        this.ChangeDoctorModal.close();
    }
    displayFn(user) {
        return user ? user.lastName + ' ' + user.firstName : '';
    }
    savePatientAdditionalInfoModal(newValue) {
        this.isLoading = true;
        this.closePatientAdditionalInfoModal();
        // tslint:disable-next-line:max-line-length
        this.treatmentsService.updatePatientAdditionalNotes(this.selectedTreatment.idTreatment, this.selectedTreatment.treatmentCode, newValue).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('patient.PATIENT.additionalInfoConfirm'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.selectedTreatment.patientAdditionalNotes = newValue;
            this.patientInfoIconColor = newValue === '' ? 'black' : 'red';
            this.cd.detectChanges();
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    getClinicPreferencesFlag() {
        const res = true;
        this._patientService.getDoctorLite(this.selectedTreatment.idDoctor).subscribe((doctor) => {
            this.clinicPreferencesUpdade = !doctor.clinicPreferencesUpdated;
            this.cd.detectChanges();
        });
    }
    setClinicPreferenceAsRead() {
        this.isLoading = true;
        this.closeDoctorAdditionalInfoModal();
        this.treatmentsService.updateClinicPreferenceFlag(+this.selectedTreatment.idDoctor).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('patient.PATIENT.clinicPreferencesUpdated'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            // refresh current treatment
            setTimeout(() => {
                this.prepareTreatmentCall();
            }, 0);
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    closeInfoModal() {
        this.infoModal.close();
    }
    confirmRecoverFromIncompleteModal() {
        this.isLoading = true;
        switch (this.selectedModalType) {
            case 'recoverTreatment':
                this.treatmentsService.changeStatus(null, null, 'EVALUATION', {
                    idTreatment: +this.treatmentId
                }).subscribe(resp => {
                    this.isLoading = false;
                    this.closeRecoverFromIncompleteModal();
                    this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.recoverMessageConfirm'), 'X', {
                        duration: 3000,
                        panelClass: 'success',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    // refresh current treatment
                    setTimeout(() => {
                        this.prepareTreatmentCall();
                    }, 0);
                });
                break;
            case 'draft':
                this.treatmentsService.changeStatus(null, null, 'draft', {
                    idTreatment: +this.treatmentId,
                    reason: this.draftReason.value == null ? '' : this.draftReason.value
                }).subscribe(resp => {
                    this.isLoading = false;
                    this.closeRecoverFromIncompleteModal();
                    this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.sendToDoctorMessageConfirm'), 'X', {
                        duration: 3000,
                        panelClass: 'success',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    // refresh current treatment
                    setTimeout(() => {
                        this.prepareTreatmentCall();
                    }, 0);
                }, error => {
                    // error while saving treatment
                    this.isLoading = false;
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                });
                break;
            case 'deleteVideo':
                this.closeRecoverFromIncompleteModal();
                this.treatmentsService.deleteWebViewer(+this.treatmentId).subscribe(resp => {
                    this.isLoading = false;
                    this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.deleteVideoMessageConfirm'), 'X', {
                        duration: 3000,
                        panelClass: 'success',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    // refresh current treatment
                    setTimeout(() => {
                        this.prepareTreatmentCall();
                    }, 0);
                }, error => {
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    this.isLoading = false;
                    this.cd.detectChanges();
                });
                break;
            case 'generateTreatmentCode':
                this.closeRecoverFromIncompleteModal();
                let archesType = this.tabThreeForm.controls.archesType.value;
                if (archesType === 'T') {
                    archesType = 'A';
                }
                this.treatmentsService.generateTreatmentCode(+this.treatmentId, archesType).subscribe((resp) => {
                    this.isLoading = false;
                    this.selectedTreatment.treatmentCode = resp.treatmentCode;
                    // if (resp.treatmentCode.substring(9,1)!='1')
                    // {
                    //   this.selectedTreatment.scheduleDate2 = Date();
                    // }
                    this.cd.detectChanges();
                    this.snackBar.open(this._translocoService.translate('patient.GENERAL_INFO.generateTreatmentCodeConfirmMessage'), 'X', {
                        duration: 3000,
                        panelClass: 'success',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                }, error => {
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    this.isLoading = false;
                    this.cd.detectChanges();
                });
                break;
        }
    }
    openTraditionalPDF() {
        // open pdf
        const link = document.createElement('a');
        const lang = this._translocoService.getActiveLang().toUpperCase();
        link.target = '_blank';
        link.href = this.storageBaseUrl + '/nuvola-support/DAI-' + lang + '.pdf';
        link.setAttribute('visibility', 'hidden');
        link.click();
    }
    prepareSendWindowData() {
        const treatmentType = {
            'NS': this._translocoService.translate('patient.GENERAL_INFO.nuvolaStandardTreatment'),
            'NP': this._translocoService.translate('patient.GENERAL_INFO.nuvolaOPSTreatment'),
            'NJ': this._translocoService.translate('patient.GENERAL_INFO.NuvolaJuniorProject'),
            'null': 'N'
        };
        const archesHandled = {
            'B': this._translocoService.translate('patient.GENERAL_INFO.bothArches'),
            'U': this._translocoService.translate('patient.GENERAL_INFO.upperOnly'),
            'L': this._translocoService.translate('patient.GENERAL_INFO.bottomOnly'),
            'null': 'NO'
        };
        let currentReturn = null;
        if (this.returns) {
            currentReturn = this.returns.find((item => item.idAddress === this.tabTwoForm.controls.return.value));
        }
        const currentShipping = this.shippings.find((item => item.idAddress === this.tabTwoForm.controls.ship.value));
        const currentInvoice = this.invoices.find((item => item.idAddress === this.tabTwoForm.controls.invoice.value));
        this.sendAdditionalInfo = [];
        if (currentReturn) {
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.ADDRESSES.ReturnLabel'),
                // tslint:disable-next-line:max-line-length
                value: `${currentReturn.activityName !== '' ? currentReturn.activityName + ' - ' : ''}${currentReturn.address} - ${currentReturn.city} - ${currentReturn.zipCode} - ${currentReturn.countryName}`,
            });
        }
        if (currentShipping) {
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.ADDRESSES.ShipLable'),
                // tslint:disable-next-line:max-line-length
                value: `${currentShipping.activityName !== '' ? currentShipping.activityName + ' - ' : ''}${currentShipping.address} - ${currentShipping.city} - ${currentShipping.zipCode} - ${currentShipping.countryName}`,
            });
        }
        if (currentInvoice) {
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.ADDRESSES.InvoiceLabel'),
                // tslint:disable-next-line:max-line-length
                value: `${currentInvoice.activityName !== '' ? currentInvoice.activityName + ' - ' : ''}${currentInvoice.address} - ${currentInvoice.city} - ${currentInvoice.zipCode} - ${currentInvoice.countryName}`,
            });
        }
        if (this.selectedTreatment.phase === '1' && this.selectedTreatment.phaseType === 'P') {
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.GENERAL_INFO.arches'),
                value: archesHandled[this.tabThreeForm.controls.archesTypeHandle.value]
            });
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.GENERAL_INFO.treatmentType'),
                value: treatmentType[this.tabThreeForm.controls.patientType.value]
            });
        }
        else {
            this.sendAdditionalInfo.push({
                key: this._translocoService.translate('patient.NEXTPHASE.videoRequestedLabel'),
                value: this.tabSixForm.controls.videoRequested.value ?
                    this._translocoService.translate('patient.NEXTPHASE.trueLabel') :
                    this._translocoService.translate('patient.NEXTPHASE.falseLabel')
            });
        }
    }
    openAdditionalButtonsModal(type, ref, confirmRef) {
        this.selectedModalType = type;
        this.reasonFormControl = null;
        this.otherReasonFormControl = null;
        switch (type) {
            case 'send':
                this.additionalModalText = this._translocoService.translate('patient.sendModalText');
                this.prepareSendWindowData();
                this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
                break;
            case 'reject':
                this.additionalModalText = this._translocoService.translate('patient.rejectModalText');
                this.reasonFormControl = new FormControl(null, Validators.required);
                this.additionalModal = this.modalServide.open(ref, { panelClass: 'lg-modal', closeOnNavigation: true });
                break;
            case 'evaluate':
                if (confirmRef) {
                    if (this.selectedTreatment.scanUpper && this.selectedTreatment.scanLower) {
                        this.showEvaluationModal(ref);
                    }
                    else {
                        this.modalMessage = 'patient.confirmEvaluation';
                        this.confirmEvaluationModalRef = ref;
                        this.confirmEvaluationModal = this.modalServide.open(confirmRef, { disableClose: true });
                    }
                }
                break;
            case 'incomplete':
                this.additionalModalText = this._translocoService.translate('patient.incompleteModalText');
                this.reasonFormControl = new FormControl(null);
                this.otherReasonFormControl = new FormControl(null);
                this.incompleteModal = this.modalServide.open(ref, { panelClass: 'lg-modal', closeOnNavigation: true });
                this._usersService.getOptions('IR').subscribe((resp) => {
                    this.otherReasonList = resp.sort(x => x.optionTitle_EN && x.optionTitle_IT);
                });
                break;
            case 'deleteTreatment':
                this.additionalModalText = this._translocoService.translate('patient.deleteModalText');
                this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
                break;
            case 'archiveTreatment':
                this.additionalModalText = this._translocoService.translate('patient.archiveModalText');
                this.additionalModal = this.modalServide.open(ref, { closeOnNavigation: true });
                break;
            default:
                return;
        }
    }
    downloadPDFFile() {
        return new Promise((resolve) => {
            const language = this._translocoService.getActiveLang();
            const idAdmin = this.currentUser.idAdmin;
            const treatmentId = this.treatmentId;
            this._patientService.getServiceSheetFile(treatmentId, language, idAdmin).subscribe((resp) => {
                downloadFile(resp).then(() => {
                    resolve();
                });
            });
        });
    }
    send() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.selectedModalType === 'incomplete') {
                if (this.reasonFormControl && (this.reasonFormControl.value && this.reasonFormControl.value.length === 0)) {
                    if (this.otherReasonFormControl && !this.otherReasonFormControl.value) {
                        this.snackBar.open(this._translocoService.translate('COMMON.ValidationErrors'), 'X', {
                            direction: 'rtl',
                            duration: 3000,
                            panelClass: 'warning',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        return;
                    }
                }
            }
            else {
                if (this.reasonFormControl && !this.reasonFormControl.valid) {
                    this.reasonFormControl.markAsTouched({ onlySelf: true });
                    return;
                }
            }
            this.isLoading = true;
            switch (this.selectedModalType) {
                case 'send':
                    // save as first then send
                    this.SaveAndSend = true;
                    this.save(false, this.fileLoadProgressModal);
                    // console.log('dopo di save');
                    // this._patientService.changeStatus(null, this.treatmentId, "evaluation").subscribe(resp => {
                    //   this.snackBar.open(this._translocoService.translate("patient.statusChangedSuccessfull"), "X", {
                    //     duration: 3000,
                    //     panelClass: "success",
                    //     horizontalPosition: "right",
                    //     verticalPosition: "top",
                    //   } as MatSnackBarConfig);
                    //   this.closeAdditionButtonModal();
                    //   setTimeout(() => {
                    //     this.prepareTreatmentCall();
                    //   }, 0);
                    //   this.isLoading = false;
                    // })
                    break;
                case 'reject':
                    this._patientService.changeStatus(this.reasonFormControl.value, this.treatmentId, 'rejected').subscribe(resp => {
                        this.snackBar.open(this._translocoService.translate('patient.statusChangedSuccessfull'), 'X', {
                            duration: 3000,
                            panelClass: 'success',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeAdditionButtonModal();
                        setTimeout(() => {
                            this.prepareTreatmentCall();
                        }, 0);
                    });
                    break;
                case 'evaluate':
                    this._patientService.changeStatus(null, this.treatmentId, 'inproject').subscribe(resp => {
                        this.snackBar.open(this._translocoService.translate('patient.statusChangedSuccessfull'), 'X', {
                            duration: 3000,
                            panelClass: 'success',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.downloadPDFFile().then(() => {
                            this.isLoading = false;
                            this.closeAdditionButtonModal();
                            this._router.navigate(['/dashboard']);
                        });
                        // redirect to the dashboard
                        // setTimeout(() => {
                        //   this.prepareTreatmentCall();
                        // }, 0);
                    });
                    break;
                case 'incomplete':
                    const request = {
                        'idTreatment': +this.treatmentId,
                        'reason': this.otherReasonFormControl.value ? this.otherReasonFormControl.value : '',
                        'reasonOptions': this.reasonFormControl.value ? [
                            ...this.reasonFormControl.value
                        ] : []
                    };
                    this._patientService.changeStatus(this.reasonFormControl.value, this.treatmentId, 'incomplete', request).subscribe(resp => {
                        this.snackBar.open(this._translocoService.translate('patient.statusChangedSuccessfull'), 'X', {
                            duration: 3000,
                            panelClass: 'success',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeIncompleteModal();
                        setTimeout(() => {
                            this.prepareTreatmentCall();
                            // refresh incomplete chat mesages
                            this._messagingService.sendMessage('refreshIncompleteTreatmentChatMessages');
                            // this.refreshIncompleteChat.next(true);
                        }, 0);
                    });
                    break;
                case 'deleteTreatment':
                    this._patientService.deleteTreatment(this.treatmentId).subscribe(resp => {
                        this.snackBar.open(this._translocoService.translate('patient.deleteSuccessfull'), 'X', {
                            duration: 3000,
                            panelClass: 'success',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeAdditionButtonModal();
                        setTimeout(() => {
                            this._router.navigate(['/dashboard'], { replaceUrl: true });
                        }, 0);
                    }, err => {
                        this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                            duration: 3000,
                            panelClass: 'warning',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeAdditionButtonModal();
                        setTimeout(() => {
                            this.prepareTreatmentCall();
                        }, 0);
                    });
                    break;
                case 'archiveTreatment':
                    this._patientService.changeStatus(null, this.treatmentId, 'archived').subscribe(resp => {
                        this.snackBar.open(this._translocoService.translate('patient.statusChangedSuccessfull'), 'X', {
                            duration: 3000,
                            panelClass: 'success',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeAdditionButtonModal();
                        setTimeout(() => {
                            this.prepareTreatmentCall();
                        }, 0);
                    }, err => {
                        this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                            duration: 3000,
                            panelClass: 'warning',
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                        });
                        this.isLoading = false;
                        this.closeAdditionButtonModal();
                        setTimeout(() => {
                            this.prepareTreatmentCall();
                        }, 0);
                    });
                    break;
                default:
                    this.isLoading = false;
                    return;
            }
        });
    }
    copied(value) {
        if (value.isSuccess) {
            this.snackBar.open(this._translocoService.translate('patient.copiedSuccess'), 'X', {
                direction: 'rtl',
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }
    }
    addressCopied(value) {
        if (value.isSuccess) {
            this.snackBar.open(this._translocoService.translate('patient.addressCopiedSuccess'), 'X', {
                direction: 'rtl',
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }
    }
    verifyUploadedVideo(ref) {
        if (this.selectedTreatment.videoUploaded) {
            // if ((this.selectedTreatment.phase != '1' || this.selectedTreatment.phaseType == 'R') && this.selectedTreatment.videoFile != '') {
            if (this.selectedTreatment.videoFile !== '' && this.selectedTreatment.videoType === 'OS') {
                // video file specified
                // tslint:disable-next-line:max-line-length
                this._contentService.getWebViewerUrlFromFile(this.selectedTreatment.treatmentCode, this.selectedTreatment.idTreatment, this.selectedTreatment.videoFile).subscribe((resp) => {
                    const a = document.createElement('a');
                    a.id = '1navigateTag';
                    a.target = resp.fullUrl;
                    a.href = resp.fullUrl;
                    a.setAttribute('onclick', resp.fullUrl);
                    document.body.appendChild(a);
                    const tag = document.getElementById('1navigateTag');
                    tag.click();
                    document.body.removeChild(a);
                });
                return;
            }
            else if (this.selectedTreatment.videoFile !== '' && this.selectedTreatment.videoType !== 'OS') {
                const a = document.createElement('a');
                a.id = '1navigateTag';
                a.target = this.selectedTreatment.videoFile;
                a.href = this.selectedTreatment.videoFile;
                a.setAttribute('onclick', this.selectedTreatment.videoFile);
                document.body.appendChild(a);
                const tag = document.getElementById('1navigateTag');
                tag.click();
                document.body.removeChild(a);
                return;
            }
            else {
                // no specified video file
                // tslint:disable-next-line:max-line-length
                this._contentService.getWebViewerUrl(this.selectedTreatment.treatmentCode, this.selectedTreatment.idTreatment).subscribe((resp) => {
                    const a = document.createElement('a');
                    a.id = '1navigateTag';
                    a.target = resp.fullUrl;
                    a.href = resp.fullUrl;
                    a.setAttribute('onclick', resp.fullUrl);
                    document.body.appendChild(a);
                    const tag = document.getElementById('1navigateTag');
                    tag.click();
                    document.body.removeChild(a);
                });
                return;
            }
        }
        this._contentService.getWebViewerFiles(this.selectedTreatment.treatmentCode).subscribe((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.selectedTreatment.videoType === 'AR') {
                try {
                    this.aliasesLoading = true;
                    this.approveOnlyAliases = (yield this._contentService.getAliases(this.selectedTreatment.arcadId).toPromise());
                    this.aliasesLoading = false;
                    if (this.approveOnlyAliases.length > 0) {
                        this.approveOnlyAlias = this.approveOnlyAliases[0].alias;
                    }
                }
                catch (e) {
                    this.isDisableSaveFile = true;
                    this.aliasesLoading = false;
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        direction: 'rtl',
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                }
            }
            else {
                this.isDisableSaveFile = false;
            }
            // show modal
            this.webViewerItems = resp;
            this.WebViewerFileSelector = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: 'lg-modal' });
        }), error => {
            this.snackBar.open(this._translocoService.translate('patient.verifiedError'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isVerifyInProgres = false;
            this.cd.detectChanges();
        });
    }
    videoChoice(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isDisableSaveFile = false;
            this.selectedTreatment.videoType = type;
            if (type === 'AR') {
                try {
                    this.aliasesLoading = true;
                    this.approveOnlyAliases = (yield this._contentService.getAliases(this.selectedTreatment.arcadId).toPromise());
                    this.aliasesLoading = false;
                    if (this.approveOnlyAliases.length > 0) {
                        this.approveOnlyAlias = this.approveOnlyAliases[0].alias;
                    }
                }
                catch (e) {
                    this.isDisableSaveFile = true;
                    this.aliasesLoading = false;
                    this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                        direction: 'rtl',
                        duration: 3000,
                        panelClass: 'warning',
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                }
            }
        });
    }
    closeWebViewerFileSelectorModal() {
        this.WebViewerFileSelector.close();
    }
    updateWebViewFile() {
        this.isLoading = true;
        this.isDisableSaveFile = true;
        let filename = '';
        switch (this.selectedTreatment.videoType) {
            case 'OS':
                filename = this.webViewerItemSelected;
                break;
            case 'OL':
                // comcat the current idtreatment
                let tmplink = this.videoLink.substring(0, this.videoLink.indexOf('&'));
                tmplink += '&fg=7bb&bg=eee&o=2&p=nuvola&t=' + this.selectedTreatment.idTreatment;
                filename = tmplink;
                break;
            case 'LK':
                filename = this.videoLink;
                break;
            case 'AR': // arcad
                // generate webview url
                // tslint:disable-next-line:radix
                const caseId = parseInt('20' + this.selectedTreatment.arcadId) * 654;
                const patientName = this.selectedTreatment.patientFullName;
                const doctorEmail = this.arcadDoctorEmail;
                // tslint:disable-next-line:max-line-length
                filename = `https://webviewer.giottonuvola.com/?caseId=${caseId}&patientName=${patientName}&vdoc=true&username=${doctorEmail}&approveOnly=${encodeURI(this.approveOnlyAlias)}`;
                break;
        }
        const request = {
            'idTreatment': this.selectedTreatment.idTreatment,
            'fileName': filename,
            'videoType': this.selectedTreatment.videoType
        };
        if (filename === undefined || filename === '') {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isLoading = false;
            this.isDisableSaveFile = false;
            return;
        }
        this.treatmentsService.updateWebViewerFile(request).subscribe(resp => {
            this.WebViewerFileSelector.close();
            this.prepareTreatmentCall();
            this.snackBar.open(this._translocoService.translate('patient.verifiedSuccess'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isLoading = false;
            this.isDisableSaveFile = false;
            this.cd.detectChanges();
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isVerifyInProgres = false;
            this.isLoading = false;
            this.isDisableSaveFile = false;
            this.cd.detectChanges();
        });
    }
    collapseContent(value) {
        this.collapsed = value;
        this.treatmentAdditionalFunctionalService.isPanelCollapsed = value;
    }
    isAllowToViewScanDnD() {
        if ((this.selectedTreatment && this.currentUser.role === 'US' && this.currentUser.userGroup === 'ST' &&
            (this.selectedTreatment.status === 'EVALUATION' || this.selectedTreatment.status === 'DRAFT' ||
                this.selectedTreatment.status === 'INCOMPLETE' || this.selectedTreatment.status === 'REJECTED' ||
                this.selectedTreatment.status === 'INPROJECT' || this.selectedTreatment.status === 'INREVIEW'))) {
            return true;
        }
        else if (this.selectedTreatment && (this.currentUser.role === 'US' && this.currentUser.userGroup === 'PJ')
            && (this.selectedTreatment.status === 'INPROJECT')
        // && this.selectedTreatment.archesType == "D" && this.selectedTreatment.archesSentViaCommunicator)
        ) {
            return true;
            // tslint:disable-next-line:max-line-length
        }
        else if (this.selectedTreatment && this.currentUser.role === 'DO' && (this.selectedTreatment.scanUpper || this.selectedTreatment.scanLower)
            &&
                (this.selectedTreatment.status === 'INPROJECT' || this.selectedTreatment.status === 'INREVIEW' ||
                    this.selectedTreatment.status === 'APPROVED' || this.selectedTreatment.status === 'PRODUCTION' ||
                    this.selectedTreatment.status === 'SHIPPED')) {
            this.disabledSTL = true;
            return true;
        }
        return false;
    }
    openScheduleModal(ref) {
        // tslint:disable-next-line:max-line-length
        this.treatmentsService.getScheduleTimes(this._convertService.dateConvert(new Date().toUTCString(), 'YYYY-MM-DDTHH:MM:SS')).subscribe((resp) => {
            this.availableScheduleTime = [];
            for (const date of resp) {
                const iteratorDate = new Date(date);
                // tslint:disable-next-line:max-line-length
                if (this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() === iteratorDate.getDate())) != null && this.availableScheduleTime.length !== 0) {
                    this.availableScheduleTime.find(x => x.every(t => new Date(t).getDate() === iteratorDate.getDate())).push(date);
                    continue;
                }
                const arrayOfDate = [];
                arrayOfDate.push(date);
                this.availableScheduleTime.push(arrayOfDate);
            }
        });
        this.schedulePickUp = this.modalServide.open(ref, { panelClass: 'lg-modal', closeOnNavigation: true });
    }
    getAvailableScheduleDate(pickupDate, pickupDate2) {
        const date = new Date(pickupDate);
        const date2 = new Date(pickupDate2);
        const prefix = this._translocoService.translate('patient.GENERAL_INFO.plannedPickupOf');
        let res = `${date.getHours()}:00-${date.getHours() + 4}:00 ${prefix} ${this._convertService.dateConvert(pickupDate, 'DD/MM/YYYY')}`;
        if (pickupDate2 != null) {
            // tslint:disable-next-line:max-line-length
            res += ` - ${date2.getHours()}:00-${date2.getHours() + 4}:00 ${prefix} ${this._convertService.dateConvert(pickupDate2, 'DD/MM/YYYY')}`;
        }
        return res;
    }
    getConvertedScheduledDate() {
        return this.getAvailableScheduleDate(this.selectedTreatment.pickupDate, this.selectedTreatment.pickupDate2);
        // return this._convertService.dateConvert(this.selectedTreatment.pickupDate, "DD/MM/YYYY HH:MM")
    }
    closeScheduleModal() {
        this.schedulePickUp.close();
    }
    scheduleButtonAllowToRender() {
        if (!this.selectedTreatment) {
            return false;
        }
        if (this.tabThreeForm.controls.archesType.value === 'D' || this.tabThreeForm.controls.archesType.value == null) {
            return false;
        }
        // tslint:disable-next-line:max-line-length
        if ((this.selectedTreatment.status === 'DRAFT' || this.selectedTreatment.status === 'INCOMPLETE') && !this.selectedTreatment.pickupDate && this.currentUser.role === 'DO') {
            return true;
        }
        return false;
    }
    addressSelection(value) {
        if (value === 'new') {
            // redirect to myprofile page
            this._router.navigate(['/myprofile']);
        }
    }
    getSelectedAddress(items, id) {
        if (items == null) {
            return;
        }
        const ret = items.filter(x => x.idAddress === id)[0];
        if (ret != null) {
            // tslint:disable-next-line:max-line-length
            return (ret.activityName !== '' ? ret.activityName + ' - ' : '') + ret.address + ' - ' + ret.city + ' - ' + ret.zipCode + ' - ' + ret.countryName;
        }
        else {
            return '';
        }
    }
    scheduledTipeAllowToRender() {
        if (this.tabThreeForm.controls.archesType.value === 'T' && this.selectedTreatment.pickupDate) {
            return true;
        }
        return false;
    }
    confirmSchedule() {
        const selectedDate = this.scheduleForm.controls.pickupDate.value;
        if (!this.scheduleForm.valid || selectedDate.length === 0) {
            this.snackBar.open(this._translocoService.translate('patient.GENERAL_INFO.scheduleTimeSelectError'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            return;
        }
        let request = this.scheduleForm.value;
        request = {
            // ...request,
            'pickupDate': selectedDate[0],
            'pickupDate2': selectedDate.length === 2 ? selectedDate[1] : null,
            'pickupNote': this.scheduleForm.controls.pickupNote.value,
            'idTreatment': this.selectedTreatment.idTreatment,
            'IdAddressReturn': this.tabTwoForm.controls.return.value
        };
        this.treatmentsService.putScheduleTime(request).subscribe(resp => {
            if (resp) {
                this.snackBar.open(this._translocoService.translate('patient.GENERAL_INFO.scheduleTimeSuccess'), 'X', {
                    direction: 'rtl',
                    duration: 3000,
                    panelClass: 'success',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
                setTimeout(() => {
                    this.closeScheduleModal();
                    this.prepareTreatmentCall();
                }, 0);
            }
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                direction: 'rtl',
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    addImageToCollection(imageName) {
        if (imageName !== '') {
            this.imagesCollection.push({ path: this.storageBaseUrl + '/t-' + this.treatmentId + '/' + imageName });
        }
    }
    handleCarouselEvents(event) {
        console.log('event', event);
    }
    next() {
        // this.myCarousel.next();
        console.log('next');
    }
    openImagePreviewModal(imagePreview) {
        // prepare image preview
        this.imagesCollection = [];
        if (this.selectedTreatment) {
            if (this.selectedTreatment.imageCollage.match(/.(jpg|jpeg|png|gif)$/i)) {
                this.addImageToCollection(this.selectedTreatment.imageCollage);
            }
            this.addImageToCollection(this.selectedTreatment.imageUA);
            this.addImageToCollection(this.selectedTreatment.imageBA);
            this.addImageToCollection(this.selectedTreatment.imageLO);
            this.addImageToCollection(this.selectedTreatment.imageFO);
            this.addImageToCollection(this.selectedTreatment.imageRO);
            this.addImageToCollection(this.selectedTreatment.imageLS);
            this.addImageToCollection(this.selectedTreatment.imageFS);
            this.addImageToCollection(this.selectedTreatment.imageRS);
            this.addImageToCollection(this.selectedTreatment.imageOrtho);
            this.addImageToCollection(this.selectedTreatment.imageTelecranio);
            this.addImageToCollection(this.selectedTreatment.imageOther);
        }
        if (this.imagesCollection.length > 0) {
            this.imagePreview = this.modalServide.open(imagePreview, { panelClass: 'lg-modal-imagepreview', closeOnNavigation: false });
        }
        else {
            this.snackBar.open(this._translocoService.translate('patient.GENERAL_INFO.imagePreviewNotFound'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }
    }
    showPatientInfoModal(ref) {
        this.PatientAdditionalInfo = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: 'lg-modal-doctorAddInfo' });
    }
    showDoctorInfoModal(ref) {
        // get data from localstorage + get clinic preferences
        this.isLoading = true;
        this._usersService.getUserById(this.selectedTreatment.idDoctor).subscribe((doctor) => {
            this.selectedTreatmentDoctor = doctor;
            if (doctor.whatsAppFlag) {
                this.doctorWhatsappNumber = doctor.whatsAppDialCode + doctor.whatsAppNumber;
            }
            else {
                this.doctorWhatsappNumber = 'N/D';
            }
            this.doctorAddInfoQuestion1 = 'questionEmpty';
            switch (doctor.question1) {
                case 'N':
                    this.doctorAddInfoQuestion1 = 'questionNo';
                    break;
                case 'Y':
                    this.doctorAddInfoQuestion1 = 'questionYes';
                    this.doctorAddInfoHowManyPatients = true;
                    break;
            }
            // this.doctorAddInfoQuestion2 = 'questionEmpty';
            // switch (doctor.question2) {
            //   case 'N':
            //     this.doctorAddInfoQuestion2 = 'questionNo';
            //     break;
            //   case 'Y':
            //     this.doctorAddInfoQuestion2 = 'questionYes';
            //     break;
            // }
            // this.doctorAddInfoQuestion3 = 'questionEmpty';
            // switch (doctor.question3) {
            //   case 'N':
            //     this.doctorAddInfoQuestion3 = 'questionNo';
            //     break;
            //   case 'Y':
            //     this.doctorAddInfoQuestion3 = 'questionYes';
            //     break;
            // }
            switch (doctor.question11) {
                case '1':
                    this.doctorAddInfoQuestion11 = 'question1.1.1';
                    break;
                case '2':
                    this.doctorAddInfoQuestion11 = 'question1.1.2';
                    break;
                case '3':
                    this.doctorAddInfoQuestion11 = 'question1.1.3';
                    break;
                case '4':
                    this.doctorAddInfoQuestion11 = 'question1.1.4';
                    break;
            }
            this._usersService.getClinicPreferences(this.selectedTreatment.idDoctor).subscribe((resp) => {
                this.isLoading = false;
                this.doctorClinicPreferences = resp.clinicPreferences;
                this.doctorInternalNotes = resp.internalNotes;
                this.doctorNotesForm = this.fb.group({
                    internalNotes: this.doctorInternalNotes,
                });
                if (this.doctorClinicPreferences === '') {
                    this.doctorClinicPreferences = 'N/A';
                }
                this.DoctorAdditionalInfo = this.modalServide.open(ref, { closeOnNavigation: true, panelClass: 'lg-modal-doctorAddInfo' });
                this.doctorUserFullName = resp.doctorUserFullName == null ? '-' : resp.doctorUserFullName;
                this.doctorAgentFullName = resp.doctorAgentFullName == null ? '-' : resp.doctorAgentFullName;
                this.doctorShowNuvolaJunior = resp.showNuvolaJunior;
                this.cd.detectChanges();
            }, error => {
                this.isLoading = false;
                this.cd.detectChanges();
                this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                    duration: 3000,
                    panelClass: 'warning',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
            });
        }, error => {
            this.isLoading = false;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    waitingForSTL_Change(event) {
        this.isLoading = true;
        this._patientService.updateWaitingForSTL(event.checked, this.selectedTreatment.idTreatment).subscribe((resp) => {
            this.waitingForSTL = event.checked;
            this.isLoading = false;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('patient.saveTreatmentSuccessful'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }, error => {
            this.isLoading = false;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    showEditForm() {
        this.isEditingNotes = true;
    }
    hideEditForm() {
        this.isEditingNotes = false;
    }
    saveDoctorNotes() {
        const data = {
            idDoctor: this.selectedTreatment.idDoctor,
            internalNotes: this.doctorNotesForm.controls.internalNotes.value
        };
        this.isLoading = true;
        this._patientService.updateDoctorNotes(data).subscribe((resp) => {
            this.isLoading = false;
            this.isEditingNotes = false;
            this.doctorInternalNotes = this.doctorNotesForm.controls.internalNotes.value;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('patient.saveTreatmentSuccessful'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }, error => {
            this.isLoading = false;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    onScheduleTimeChecked(event, row) {
        let selectedDate = this.scheduleForm.controls.pickupDate.value;
        if (!event.checked) {
            selectedDate = selectedDate.filter(x => x !== row);
            this.scheduleForm.controls.pickupDate.setValue(selectedDate);
            return;
        }
        if (selectedDate == null || selectedDate.length === 0) {
            selectedDate = [];
            selectedDate.push(row);
            this.scheduleForm.controls.pickupDate.setValue(selectedDate);
            return;
        }
        if (selectedDate.length === 2) {
            event.source._checked = false;
            this.snackBar.open(this._translocoService.translate('COMMON.allowedSelectedMaximumLimit'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            return;
        }
        if (new Date(row).getDate() !== new Date(selectedDate[0]).getDate()) {
            event.source._checked = false;
            this.snackBar.open(this._translocoService.translate('COMMON.couldBeSelectedOnlySameDay'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            return;
        }
        selectedDate.push(row);
        this.scheduleForm.controls.pickupDate.setValue(selectedDate);
    }
    openCheckSendToNuvolaModal(modalType) {
        return Observable.create((observer) => {
            const dialogRef = this.modalServide.open(TreatmentModalComponent, {
                width: '500px',
                disableClose: true,
                data: { idTreatment: this.selectedTreatment.idTreatment, modalType: modalType }
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('res', result);
                observer.next(result);
                observer.complete();
            }, (error) => {
                observer.next(false);
                observer.complete();
            });
        });
    }
    showNuvolaJunior() {
        if (this.currentUser && this.currentUser.role === 'DO') {
            // logged in user is a DOCTOR
            return this.currentUser.showNuvolaJunior;
        }
        else {
            // logged in user is NOT a DOCTOR
            return true;
        }
    }
    showNuvolaYoung() {
        if (this.currentUser && this.currentUser.role === 'DO') {
            // logged in user is a DOCTOR
            return this.currentUser.showNuvolaYoung;
        }
        else {
            // logged in user is NOT a DOCTOR
            return true;
        }
    }
    getThroughValue() {
        if (this.tabFiveForm.controls.youngThroughValue.value) {
            const mapValues = {
                'DS': 'distortion',
                'DZ': 'distalization',
                'IP': 'ipr',
                'EL': 'elastic',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.youngThroughValue.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getDeepCaseStudies() {
        if (this.tabFiveForm.controls.youngDeepValues.value) {
            const mapValues = {
                'UE': 'upperDeepExtrusion',
                'LE': 'lowerDeepExtrusion',
                'UI': 'upperDeepIntrusion',
                'LI': 'lowerDeepIntrusion',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.youngDeepValues.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getOpenCaseStudies() {
        if (this.tabFiveForm.controls.youngOpenValues.value) {
            const mapValues = {
                'UE': 'upperOpenExtrusion',
                'LE': 'lowerOpenExtrusion',
                'UI': 'upperOpenIntrusion',
                'LI': 'lowerOpenIntrusion',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.youngOpenValues.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getCrowdingUpperArchWorkflowValues() {
        if (this.tabFiveForm.controls.crowdingUpperArchWorkflow.value) {
            const mapValues = {
                'A': 'archExpansion',
                'I': 'IPR',
                'C': 'centerLineCentering',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.crowdingUpperArchWorkflow.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getCrowdingLowerArchWorkflowValues() {
        if (this.tabFiveForm.controls.crowdingLowerArchWorkflow.value) {
            const mapValues = {
                'A': 'archExpansion',
                'I': 'IPR',
                'C': 'centerLineCentering',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.crowdingLowerArchWorkflow.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getPredictUpperAreaValues() {
        if (this.tabFiveForm.controls.youngIPRUpper.value) {
            const mapValues = {
                'L': 'predictAreaRight',
                'R': 'predictAreaLeft',
                'F': 'predictAreaFront',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.youngIPRUpper.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    getPredictLowerAreaValues() {
        if (this.tabFiveForm.controls.youngIPRLower.value) {
            const mapValues = {
                'R': 'predictAreaRight',
                'L': 'predictAreaLeft',
                'F': 'predictAreaFront',
            };
            // tslint:disable-next-line:max-line-length
            return this.tabFiveForm.controls.youngIPRLower.value.map(item => this._translocoService.translate(`patient.OBJECTIVES.${mapValues[item]}`));
        }
    }
    onTreatmentTypeChange(treatmentType) {
        console.log('treatmentType', treatmentType);
        if (this.selectedTreatment && treatmentType === 'NJ') {
            // It's JUNIOR => All other images are mandatory
            this.showAllOtherImageMandatoryAsterisk = true;
            this.tabFourForm.controls.leftSide.setValidators(Validators.required);
            this.tabFourForm.controls.leftSide.setValue(this.tabFourForm.controls.leftSide.value);
            this.tabFourForm.controls.frontSide.setValidators(Validators.required);
            this.tabFourForm.controls.frontSide.setValue(this.tabFourForm.controls.frontSide.value);
            this.tabFourForm.controls.rightSide.setValidators(Validators.required);
            this.tabFourForm.controls.rightSide.setValue(this.tabFourForm.controls.rightSide.value);
            // this.tabFourForm.controls.rsOrthopanoramics.setValidators(Validators.required);
            // this.tabFourForm.controls.rsOrthopanoramics.setValue(this.tabFourForm.controls.rsOrthopanoramics.value);
            // this.tabFourForm.controls.telecranio.setValidators(Validators.required);
            // this.tabFourForm.controls.telecranio.setValue(this.tabFourForm.controls.telecranio.value);
        }
        else {
            this.showAllOtherImageMandatoryAsterisk = false;
            this.tabFourForm.controls.leftSide.clearValidators();
            // this.tabFourForm.controls.leftSide.reset();
            this.tabFourForm.controls.frontSide.clearValidators();
            // this.tabFourForm.controls.frontSide.reset();
            this.tabFourForm.controls.rightSide.clearValidators();
            //this.tabFourForm.controls.rightSide.reset();
            //this.tabFourForm.controls.rsOrthopanoramics.clearValidators();
            //this.tabFourForm.controls.rsOrthopanoramics.reset();
            //this.tabFourForm.controls.telecranio.clearValidators();
            //this.tabFourForm.controls.telecranio.reset();
        }
    }
    saveAddresses() {
        const request = {
            'idTreatment': +this.treatmentId,
            'idAddressShipping': this.tabTwoForm.controls.ship.value,
            'idAddressInvoice': this.tabTwoForm.controls.invoice.value
        };
        this.treatmentsService.updateAddresses(request).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate('patient.ADDITIONAL.updateAddressesMessageConfirm'), 'X', {
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            // refresh current treatment
            setTimeout(() => {
                this.prepareTreatmentCall();
            }, 0);
        }, error => {
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
            this.isLoading = false;
            this.cd.detectChanges();
        });
    }
    getFullAddress(addressInfo) {
        let res = '';
        console.log('addressInfo', addressInfo);
        if (addressInfo !== undefined && addressInfo != null) {
            if (addressInfo.addressInfo !== '') {
                res = addressInfo.addressInfo + ' - ';
            }
            res += addressInfo.address + ' - ' + addressInfo.city + ' - ' + addressInfo.zipCode + ' - ' + addressInfo.countryName;
        }
        return res;
    }
    printDate(dateValue) {
        if (dateValue == null) {
            return '-';
        }
        const momentVariable = moment(dateValue);
        return momentVariable.format('DD/MM/YYYY H:mm');
    }
    getColorNotes(fieldname) {
        switch (fieldname) {
            case 'specialInstructionsLabel':
                if (this.disabled) {
                    // gray
                    return 'rgba(0, 0, 0, 0.7)';
                }
                else {
                    if (this.tabSixForm.valid) {
                        // black
                        return 'rgb(0, 0, 0)';
                    }
                    else {
                        // red
                        return 'rgb(244, 67, 54)';
                    }
                }
                break;
            case 'archesType':
                if (this.disabled) {
                    // gray
                    return 'rgba(0, 0, 0, 0.7)';
                }
                else {
                    if (this.tabThreeForm.controls.archesType.value === 'T' || this.tabThreeForm.controls.archesType.value === 'D') {
                        // black
                        return 'rgb(0, 0, 0)';
                    }
                    else {
                        // red
                        return 'rgb(244, 67, 54)';
                    }
                }
                break;
            case 'noExcludeTeethLabel':
                if (this.disabled) {
                    // gray
                    return 'rgba(0, 0, 0, 0.7)';
                }
                else {
                    if (this.tabFiveForm.valid) {
                        // black
                        return 'rgb(0, 0, 0)';
                    }
                    else {
                        // red
                        return 'rgb(244, 67, 54)';
                    }
                }
                break;
            default:
                if (this.disabled) {
                    // gray
                    return 'rgba(0, 0, 0, 0.7)';
                }
                else {
                    // black
                    return 'rgb(0, 0, 0)';
                }
                break;
        }
    }
    downloadServiceSheet() {
        this.isDownloadingServiceSheet = true;
        const language = this._translocoService.getActiveLang();
        const idAdmin = this.currentUser.idAdmin;
        const treatmentId = this.treatmentAdditionalFunctionalService.treatmentId;
        this._patientService.getServiceSheetFile(treatmentId, language, idAdmin).subscribe((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield downloadFile(resp);
            this.isDownloadingServiceSheet = false;
            this.cd.detectChanges();
        }), error => {
            this.isDownloadingServiceSheet = false;
            this.snackBar.open(this._translocoService.translate('patient.downloadTreatmentPlanError'), 'X', {
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
    serviceSheetData(ref) {
        this.serviceSheetDataModal = this.modalServide.open(ref, { closeOnNavigation: true });
    }
    closeModal() {
        this.serviceSheetDataModal.close();
    }
    onClinicProjectChange(ref, checked) {
        if (checked) {
            this.clinicProjectModal = this.modalServide.open(ref, { closeOnNavigation: false, width: '500px', disableClose: true });
        }
    }
    applyClinicProject() {
        this.tabThreeForm.controls.clinicProject.setValue(true);
        this.clinicProjectModal.close();
    }
    resetClinicProject() {
        this.tabThreeForm.controls.clinicProject.setValue(false);
        this.clinicProjectModal.close();
    }
    submitServiceSheetData() {
        const data = this.serviceSheetForm.value;
        this.isUploadServiceData = true;
        this._patientService.submitServiceSheet(data).subscribe(resp => {
            this.closeModal();
            this.isUploadServiceData = false;
            this.cd.detectChanges();
            this.snackBar.open(this._translocoService.translate('patient.serviceSheetData'), 'X', {
                direction: 'rtl',
                duration: 3000,
                panelClass: 'success',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        }, error => {
            this.isUploadServiceData = false;
            this.snackBar.open(this._translocoService.translate('COMMON.ErrorMessage'), 'X', {
                direction: 'rtl',
                duration: 3000,
                panelClass: 'warning',
                horizontalPosition: 'right',
                verticalPosition: 'top',
            });
        });
    }
}
// @ts-ignore
export function customNullValidator() {
    return (control) => {
        // tslint:disable-next-line:triple-equals
        return control.value == 'null' ? { value: control.value } : null;
    };
}
// @ts-ignore
export function customFalseValidator() {
    return (control) => {
        // tslint:disable-next-line:triple-equals
        return control.value == false ? { value: control.value } : null;
    };
}
